import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Pipe({
	name: 'highlightJson',
})
export class HighlightJsonPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: any): SafeHtml {
		if (!value) {
			return value
		}

		// Converte o objeto para string JSON formatada
		let jsonString = JSON.stringify(value, null, 6)

		// Adiciona cor para as propriedades (chaves)
		jsonString = jsonString.replace(/\"([^"]+)\":/g, '<span style="color: #054995; font-weight: bold;">"$1"</span>:')

		// Adiciona cor para os valores
		jsonString = jsonString.replace(/:\s?\"([^"]+)\"/g, ': <span style="color: #2a9d8f;">"$1"</span>')
		jsonString = jsonString.replace(/:\s?(\d+)/g, ': <span style="color: #4a4e69;">$1</span>') // para números
		jsonString = jsonString.replace(/:\s?(true|false)/g, ': <span style="color: #ef233c;">$1</span>') // para booleanos

		// Retorna o JSON formatado e seguro
		return this.sanitizer.bypassSecurityTrustHtml('<pre>' + jsonString + '</pre>')
	}
}
