<div class="card m-3 stretch-card" style="height: 91vh">
	<div class="card-header card-header-customer py-3" style="text-align: center">
		<h2 class="title text-primary">{{ title }}</h2>
	</div>
	<div class="card-header card-header-customer py-2 px-2 d-flex justify-content-between align-items-center">
		<div class="input-group search w-25 my-3" style="min-width: 16rem">
			<input class="form-control" type="text" placeholder="Pesquisar na página" [(ngModel)]="searchTermValue" name="searchTerm" style="height: 2.65rem" />
		</div>
		<form class="row row-cols-sm-auto d-flex justify-content-center align-items-center my-3">
			<div class="col-12">
				<div class="form-group" style="width: 30rem">
					<input
						type="text"
						name="bsValue"
						class="form-control"
						bsDaterangepicker
						placeholder="YYYY-MM-DD  - YYYY-MM-DD"
						[bsConfig]="bsConfig"
						(bsValueChange)="onDateSelection($event)"
						#daterangepickerInput
            style="height: 2.63rem"
					/>
				</div>
				<div class="custom-alert" role="alert" *ngIf="requiredMessage && selectedDate === null" style="margin-top: 5px">
					{{ requiredMessage }}
				</div>
			</div>

			<div class="col-12 px-0" style="min-width: 16rem">
				<div class="form-group">
					<select
						class="form-control form-select border-opacity-50 p-2"
						[(ngModel)]="selectedIntegrato"
						name="selectedIntegrato"
						(ngModelChange)="onChangeIntegrato($event)"
					>
						<option [selected]="true" disabled>Integrado</option>
						<option *ngFor="let integrado of integracao" [value]="integrado">{{ integrado }}</option>
					</select>
				</div>
			</div>

			<div class="col-12 d-flex align-items-center">
				<button type="button" class="btn btn-log-customer btn-customer-info" (click)="requiredVerification()">
					<em i-bs name="search"></em>
				</button>
				<button type="button" class="btn btn-log-customer btn-customer-danger" (click)="clearFilters(daterangepickerInput)">
					<em i-bs name="x" width="23" height="23"></em>
				</button>
			</div>
		</form>
	</div>
	<div class="card-body py-4">
		<div class="text-primary text-center" *ngIf="messageInfoRequisicao.status == 0">
			<strong>{{ messageInfoRequisicao.message }}</strong>
		</div>
		<div class="text-primary text-center" *ngIf="messageInfoRequisicao.status == 1">
			<strong>{{ messageInfoRequisicao.message }}</strong>
		</div>
		<div class="d-flex justify-content-center align-items-center py-5" *ngIf="messageInfoRequisicao.status == 2">
			<div class="spinner-border text-primary" role="status"></div>
		</div>
		<div class="table-responsive" *ngIf="cadastro && messageInfoRequisicao.status == 3">
			<table class="table table-sm table-hover align-middle">
				<caption style="display: none">
					Tabela mostra informações do envio de dados de cadastro.
				</caption>
				<thead class="table-light">
					<tr>
						<th scope="col" class="text-center align-middle">Matricula</th>
						<th scope="col" class="text-center align-middle">Integrado</th>
						<th scope="col" class="text-center align-middle">Destino</th>
						<th scope="col" class="text-center align-middle">Data</th>
						<th scope="col" class="text-center align-middle" style="width: 8rem">Detalhes</th>
						<th scope="col" class="text-center align-middle" style="width: 8rem">Reprocessar</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let cadastro of pagefiltered">
						<td class="text-center align-middle">{{ cadastro.identity }}</td>
						<td class="text-center align-middle">
							<em [style.color]="cadastro.integrato ? 'green' : 'red'" i-bs name="circle-fill" width="8" height="8"></em>
						</td>

						<td class="text-center align-middle">{{ cadastro.destiny }}</td>
						<td class="text-center align-middle">{{ cadastro.date }}</td>
						<td class="text-center align-middle" style="width: 8rem">
							<button type="button" class="btn btn-customer-info" (click)="detailModal(cadastro, modalDetail)">
								<em i-bs name="justify" width="20" height="20"></em>
							</button>
						</td>
						<td class="text-center align-middle" style="width: 8rem">
							<button type="button" class="btn btn-customer-info" (click)="reprecessModal(cadastro, modalReprecess)">
								<em i-bs name="arrow-repeat" width="20" height="20"></em>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer card-footer-customer py-4 px-2 d-flex justify-content-between align-items-center">
		<ngb-pagination class="pagination-customer" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshPage()">
		</ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshPage()">
			<option [value]="500">500 items por página</option>
			<option [value]="1000">1000 items por página</option>
			<option [value]="1500">1500 items por página</option>
		</select>
	</div>
</div>

<ng-template #modalDetail let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="card" *ngIf="detail.search != null">
			<div class="card-header"><strong>Search</strong></div>
			<div class="card-body">
				<div [innerHTML]="detail.search | highlightJson"></div>
			</div>
		</div>
		<div class="d-flex">
			<div
				class="card"
				*ngIf="detail.request != null"
				[ngClass]="{ 'full-width': detail.request == null || detail.response == null, 'half-width': detail.request != null && detail.response != null }"
			>
				<div class="card-header"><strong>Request</strong></div>
				<div class="card-body">
					<div [innerHTML]="detail.request | highlightJson"></div>
				</div>
			</div>
			<div
				class="card"
				*ngIf="detail.response != null"
				[ngClass]="{ 'full-width': detail.response == null || detail.request == null, 'half-width': detail.request != null && detail.response != null }"
			>
				<div class="card-header"><strong>Response</strong></div>
				<div class="card-body">
					<div [innerHTML]="detail.response | highlightJson"></div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #modalReprecess let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Reprocessar</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="onDismiss(0)"></button>
	</div>
	<div class="modal-body">
		<div class="d-flex flex-column justify-content-center align-items-center py-4" *ngIf="messageInfoModal.status == 1">
			<div class="spinner-border text-primary my-4" role="status"></div>
			<strong>{{ messageInfoModal.message }}</strong>
		</div>
		<div *ngIf="messageInfoModal.status == 0">
			<div class="modal-body d-flex justify-content-center align-items-center w-100">
				<!-- Envio para o credPrev -->
				<div class="w-100 d-flex">
					<div class="card w-50">
						<div class="card-header"><strong>Request</strong></div>
						<div class="card-body">
							<div [innerHTML]="response.content | highlightJson"></div>
						</div>
					</div>
					<div class="card w-50">
						<div class="card-header"><strong>Response</strong></div>
						<div class="text-center py-4" *ngIf="messageInfoReprocess.status == 0">
							<strong>{{ messageInfoReprocess.message }}</strong>
						</div>
						<div *ngIf="messageInfoReprocess.status == 1">
							<div class="d-flex flex-column justify-content-center align-items-center py-4">
								<div class="spinner-border text-primary my-4" role="status"></div>
							</div>
						</div>
						<div *ngIf="messageInfoReprocess.status == 2">
							<div [innerHTML]="reprocessResponse | highlightJson"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer d-flex justify-content-between">
		<p class="text-primary"><strong>Tem certeza que deseja reprocessar?</strong></p>
		<div>
			<button class="btn btn-customer-info reprocess m-1" style="width: 2.5rem" (click)="reprocess(response.content, response.destiny, response.erroTecnico)">
				SIM
			</button>
			<button class="btn btn-customer-danger reprocess m-1" style="width: 2.5rem" (click)="modal.dismiss('Cross click')">NÃO</button>
		</div>
	</div>
</ng-template>
