import { Injectable, OnInit } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { environment } from '../../environments/environment'

const profile_hermes_tecnico = environment.perfisUserHermes.hermes_tecnico
const profile_hermes_cadastro = environment.perfisUserHermes.hermes_cadastro
const profile_hermes_pta = environment.perfisUserHermes.hermes_pta
const profile_hermes_financeiro = environment.perfisUserHermes.hermes_financeiro

const profile_pta_cadastro = environment.perfisUserPTA.pta_cadastro
const profile_pta_arrecadacao = environment.perfisUserPTA.pta_arrecadacao
const profile_pta_emprestimo = environment.perfisUserPTA.pta_emprestimo
const profile_pta_consignataria = environment.perfisUserPTA.pta_consignataria


@Injectable()
export class ProfileService implements OnInit {
	groups: any = []

	constructor(private msalService: MsalService) {
		this.groups = this.msalService.instance.getAllAccounts()[0].idTokenClaims!['groups']
	}

	ngOnInit(): void {}


  //Hermes Tecnico
	checkedProfileHermesTecnico(): boolean {
		return this.groups?.includes(profile_hermes_tecnico) ? true : false
	}

	//Hermes Cadastro
	checkedProfileHermesCadastro(): boolean {
		return this.groups?.includes(profile_hermes_cadastro) ? true : false
	}

	// Hermes PTA
	checkedProfileHermesPTA() {
		return this.groups?.includes(profile_hermes_pta) ? true : false
	}

  //Hermes Financeiro
  checkedProfileHermesFinanceiro(): boolean {
		return this.groups?.includes(profile_hermes_financeiro) ? true : false
	}

	//PTA Cadastro
	checkedProfilePtaCadastro(): boolean {
		return this.groups?.includes(profile_pta_cadastro) ? true : false
	}

	// PTA Arrecadacao
	checkedProfilePtaArrecadacao(): boolean {
		return this.groups?.includes(profile_pta_arrecadacao) ? true : false
	}

	// PTA Emprestimo
	checkedProfilePtaEmprestimo(): boolean {
		return this.groups?.includes(profile_pta_emprestimo) ? true : false
	}

  // PTA Consignataria
	checkedProfilePtaConsignataria(): boolean {
		return this.groups?.includes(profile_pta_consignataria) ? true : false
	}

}
