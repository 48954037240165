<div class="card stretch-card">
	<nav class="navbar navbar-expand-lg navbar-light bg-light m-0">
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/monitoring/insights/alerts/querys']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						>QUERYS</a
					>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/monitoring/insights/alerts/records']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						>RECORDS
					</a>
				</li>
			</ul>
		</div>
	</nav>
	<div class="card-body">
		<router-outlet></router-outlet>
	</div>
</div>

