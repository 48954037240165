<div class="card stretch-card">
	<!-- <div class="card-header card-header-customer py-3" style="text-align: center">
		<h2 class="text-primary">DETALHES</h2>
	</div> -->
	<div class="card-body d-flex w-100 m-0 p-0 justify-content-center">
		<div class="row w-100">
			<div class="col-3" style="height: 78.5vh; overflow-y: scroll; overflow-x: hidden; padding: 0 0.8rem 0 0">
        <div class="d-flex justify-content-center align-items-center p-5" *ngIf="messageInfo.status == 2" style="height: 100%">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
				<ul class="list-group list-group-flush" style="list-style: none; padding: 0" *ngIf="messageInfo.status == 3">
					<li class="m-0 p-0" *ngFor="let registro of registroDetails">
						<a
							class="list-group-item m-0"
              [routerLink]="routerType === 'failures' ? '/monitoring/insights/failures/details/' + registro.id : '/monitoring/insights/performance/details/' + registro.id"
							[routerLinkActive]="['active']"
							[routerLinkActiveOptions]="{ exact: true }"
							(click)="handleClick(registro)"
						>
							<strong>{{ registro.timestamp | date : 'dd-MM-YYYY hh:mm:ss' }}</strong>
							<p>{{ registro.name }}</p>
							<div class="d-flex justify-content-between">
								<p>Duration: {{ getFormattedDuration(registro.duration) }}</p>
								<p>Response code: <strong [style.color]="registro.resultCode == '200' ? 'green' : 'red'">{{ registro.resultCode }}</strong> </p>
							</div>
						</a>
					</li>
				</ul>
			</div>
			<div class="col-9" style="height: 78.5vh; max-width: 69vw; overflow-y: auto; overflow-x: hidden;">
				<div class="w-100 h-100 d-flex justify-content-center align-items-center" *ngIf="registro == null">
					<img src="{{ valia }}" alt="Home" aria-label="Home" />
				</div>
				<router-outlet *ngIf="registro != null"></router-outlet>
			</div>
		</div>
	</div>
</div>
