import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { MsalService } from '@azure/msal-angular'

import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private msalService: MsalService) {}
	private isAdminGroup = false
	groups: any = {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const profile = this.msalService.instance.getAllAccounts()
		if (profile) {
			this.groups = profile[0]?.idTokenClaims!['groups']
			return (this.isAdminGroup = this.groups)
		} else {
			return (this.isAdminGroup = false)
		}
	}
}
