import { InsightService } from './pages/monitoring/insight/insight.service'
import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { Injectable, NgModule } from '@angular/core'
import { registerLocaleData, DatePipe, CommonModule } from '@angular/common'

import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import localePt from '@angular/common/locales/pt'

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'

import { NgbTooltipModule, NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons'
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr'

import {
	NgbPaginationModule,
	NgbDatepickerModule,
	NgbInputDatepickerConfig,
	NgbModule,
	NgbProgressbarModule,
	NgbProgressbarConfig,
	NgbScrollSpyModule,
} from '@ng-bootstrap/ng-bootstrap'

import { NgxEchartsModule } from 'ngx-echarts'
import * as echarts from 'echarts'

import { HighlightJsonPipe } from './shared/highlight-json.pipe'
import { HighlightXmlPipe } from './shared/highlight-xml.pipe'
import { ProfileService } from './shared/profile.service'
import { LoginService } from './shared/login.service'
import { DataFormatterService } from './shared/data-formatter.service'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ContentComponent } from './components/content/content.component'
import { FooterComponent } from './components/footer/footer.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { DataLakeDetailCardComponent } from './components/performance-data-lake/data-lake-detail-card/data-lake-detail-card.component'
import { DataLakeStatusCardComponent } from './components/performance-data-lake/data-lake-status-card/data-lake-status-card.component'
import { DataLakeDeviceCardComponent } from './components/performance-data-lake/data-lake-device-card/data-lake-device-card.component'
import { DataLakeLocationCardComponent } from './components/performance-data-lake/data-lake-location-card/data-lake-location-card.component'

import { HomeComponent } from './pages/home/home.component'
import { CadastroComponent } from './pages/cadastro/cadastro.component'
import { PtaComponent } from './pages/pta/pta.component'

import { FinanceiroComponent } from './pages/financeiro/financeiro.component'
import { GenericFinancialComponent } from './pages/financeiro/generic-financial/generic-financial.component';

import { MonitoringComponent } from './pages/monitoring/monitoring.component'
import { MetricComponent } from './pages/monitoring/insight/metric/metric.component'

import { DataLakeComponent } from './pages/monitoring/dataLake/data-lake.component'
import { SigninDataLakeComponent } from './pages/monitoring/dataLake/signin-data-lake/signin-data-lake.component'
import { ApimDataLakeComponent } from './pages/monitoring/dataLake/apim-data-lake/apim-data-lake.component'

import { InsightComponent } from './pages/monitoring/insight/insight.component'
import { GenericInsightComponent } from './pages/monitoring/insight/generic-insight/generic-insight.component'
import { DetailInsightComponent } from './pages/monitoring/insight/detail-insight/detail-insight.component'
import { OperationDetailComponent } from './pages/monitoring/insight/detail-insight/operation-detail/operation-detail.component'

import { AlertsComponent } from './pages/alerts/alerts.component'
import { QueryAlertsComponent } from './pages/alerts/query-alerts/query-alerts.component'
import { RecordAlertsComponent } from './pages/alerts/record-alerts/record-alerts.component'

import { msalConfig, protectedResources } from '../environments/environment'

registerLocaleData(localePt)

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const authReq = req.clone({
			setHeaders: {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
			},
		})
		return next.handle(authReq)
	}
}

@NgModule({
	declarations: [
		AppComponent,
		ContentComponent,
		FooterComponent,
		SidebarComponent,
		HomeComponent,
		CadastroComponent,
		MonitoringComponent,
		PtaComponent,
		HighlightJsonPipe,
		MetricComponent,
		SigninDataLakeComponent,
		ApimDataLakeComponent,
		DataLakeDetailCardComponent,
		DataLakeStatusCardComponent,
		DataLakeDeviceCardComponent,
		DataLakeLocationCardComponent,
		DataLakeComponent,
		InsightComponent,
		GenericInsightComponent,
		DetailInsightComponent,
		OperationDetailComponent,
		HighlightXmlPipe,
		FinanceiroComponent,
		GenericFinancialComponent,
    AlertsComponent,
    QueryAlertsComponent,
    RecordAlertsComponent,
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		NgxBootstrapIconsModule.pick(allIcons),
		NgbTooltipModule,
		NgbDropdownModule,
		NgbPaginationModule,
		NgbCollapseModule,
		NgbModule,
		NgbProgressbarModule,
		NgbDatepickerModule,
		NgbScrollSpyModule,
    BsDatepickerModule.forRoot(),
		NgxEchartsModule.forRoot({ echarts }),
		ToastrModule.forRoot(),
		MsalModule.forRoot(
			new PublicClientApplication(msalConfig),
			{
				interactionType: InteractionType.Redirect,
				authRequest: {
					scopes: ['user.read'],
				},
			},
			{
				interactionType: InteractionType.Redirect,
				protectedResourceMap: new Map([[protectedResources.endpoint, protectedResources.scopes]]),
			}
		),
	],
	exports: [HighlightJsonPipe],
	providers: [
		NgbInputDatepickerConfig,
		NgbProgressbarConfig,
		MsalService,
		MsalGuard,
		DatePipe,
		LoginService,
		ProfileService,
		InsightService,
		DataFormatterService,
    BsLocaleService,
		{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {}
