import { Component, OnInit, TemplateRef } from '@angular/core'
import { NgbModal, NgbModalRef, ModalDismissReasons, NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

import { Cadastro } from '../../models/cadastro.model'

import { CadastroService } from './cadastro.service'

import { LoginService } from '../../shared/login.service'
import { NotificationService } from '../../shared/notification.service'
import { RegistroCosmosService } from '../../shared/registro-cosmos.service'
import { DataFormatterService } from './../../shared/data-formatter.service'
import { FormControl } from '@angular/forms'
import { environment } from '../../../environments/environment'

const cosmos_table = environment.COSMOS_TABLE

@Component({
	selector: 'app-cadastro',
	templateUrl: './cadastro.component.html',
	styleUrl: './cadastro.component.scss',
})
export class CadastroComponent implements OnInit {
	title = 'CADASTRO'
	dynamicUrl = '/cadastro'
	loginDisplay = false

	cadastro: Cadastro | null = new Cadastro()
	cadastros: Array<Cadastro> = []
	cadastroRegistroAtividade: Cadastro = new Cadastro()

	response = { erroTecnico: false, destiny: '', content: null }
	reprocessResponse = null
	detail = { request: null, response: null, search: null }

	messageInfoRequisicao = { status: 0, message: 'Informe os campos para efetuar uma pesquisa!' }
	messageInfoModal = { status: 0, message: '' }
	messageInfoReprocess = { status: 0, message: 'O resultado será exibido após a confirmação do reprocessamento!' }

	integracao: Array<string> = ['true', 'false']
  integrato: string = ''


	query = {}
	reprocessDestiny: string = ''

	// modal
	size: string = ''
	modalRef!: NgbModalRef
	closeResult: string = ''

	requiredMessage: string = ''
	dataInicio: string = 'Data Início'
	dataFim: string = 'Data Fim'

	// search
	searchTerm: string
	filtered: Array<Cadastro> = []
	filter = new FormControl('')

  selectedIntegrato = 'Integrado'

	// paginação
	page = 1
	pageSize = 500
	collectionSize: number = 0
	pagefiltered: Array<Cadastro> = []

	// calendario
	hoveredDate: NgbDate | null = null
	fromDate: NgbDate | null = null
	toDate: NgbDate | null = null
	model: NgbDateStruct

	constructor(
    public formatter: NgbDateParserFormatter,
		private cadastroService: CadastroService,
		private modalService: NgbModal,
		private calendar: NgbCalendar,
		private loginService: LoginService,
		private notificationService: NotificationService,
		private dataFormatterService: DataFormatterService,
		private registroCosmosService: RegistroCosmosService
  ) {
    const currentDate = this.calendar.getToday()
    this.model = this.calendar.getNext(currentDate, 'm', -1)
  }

	ngOnInit(): void {
		const currentDate = this.calendar.getToday()
		this.model = this.calendar.getNext(currentDate, 'm', -1)

		this.loginService.ngOnInit()
		this.loginDisplay = this.loginService.authenticated
		this.readDataRegister()
	}

	adicionaZero(numero: number) {
		if (numero <= 9) return '0' + numero
		else return numero
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.filtered = this.cadastros
		this.searchTerm = value
		this.filtered = this.searchTerm ? this.onFilter(this.searchTerm) : this.cadastros
		this.collectionSize = this.filtered.length
		this.refreshPage()
  }


	onFilter(text: string): Array<Cadastro> {
		return this.cadastros.filter((cadastro) => {
			const term = text.toLowerCase()
			return (
				cadastro.identity.toLowerCase().includes(term) ||
        cadastro.destiny.toLowerCase().includes(term)
			)
		})
	}

	refreshPage() {
		this.pagefiltered = this.searchTerm
			? this.filtered
					.map((cadastro, i) => ({ index: i + 1, ...cadastro }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
			: this.cadastros
					.map((cadastro, i) => ({ index: i + 1, ...cadastro }))
					.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	cleanFiltered() {
		this.collectionSize = this.cadastros.length
		this.refreshPage()
    this.searchTerm = ""
		this.selectedIntegrato = 'Integrado'
		this.fromDate = null
		this.toDate = null
		this.requiredMessage = ''
		this.integrato = ''
	}

	cleanAndReload() {
		this.cleanFiltered()
		this.readDataRegister()
	}

	convertToTimestamp(dateString: string) {
		const date = new Date(dateString)
		return date.getTime() / 1000
	}

	readDataRegister() {
		this.messageInfoRequisicao = { status: 0, message: '' }
		var date = new Date()

		var dayInitial = String(date.getDate())
		var monthInitial = String(date.getMonth() + 1)
		var yearInitial = date.getFullYear()

		var formattedDateInitial = this.convertToTimestamp(`${yearInitial}/${monthInitial}/${dayInitial} 00:00:00`)
		var formattedDateEnd = this.convertToTimestamp(`${yearInitial}/${monthInitial}/${dayInitial} 23:59:59`)

		const conditions = [
			this.integrato !== '' ? `c.integrato = ${this.integrato}` : ''
		]
			.filter(Boolean)
			.join(' AND ')


		const initialDate =
			this.fromDate == null ? formattedDateInitial : this.convertToTimestamp(`${this.fromDate!.year}-${this.fromDate!.month}-${this.fromDate!.day} 00:00:00`)
		const dataEnd = this.toDate == null ? formattedDateEnd : this.convertToTimestamp(`${this.toDate!.year}-${this.toDate!.month}-${this.toDate!.day} 23:59:59`)

		this.query = { 
      query: `SELECT * FROM c WHERE c.system='cadastro' AND (c._ts >= ${initialDate} AND c._ts <= ${dataEnd}) ${
        conditions.length > 0 ? 'AND' : ''
      } ${conditions} ORDER BY c._ts DESC`,
    }

    console.log(this.query, 'query')

		this.messageInfoRequisicao = { status: 2, message: '' }
    this.registroCosmosService.readRegisterCosmosSDK(cosmos_table, 'registros-hermes', this.query).subscribe({
			next: (result) => {
				this.cleanFiltered()
				if (result._count == 0) {
					this.messageInfoRequisicao = { status: 1, message: 'Resultado não encontrado!' }
				} else {
					this.messageInfoRequisicao = { status: 3, message: '' }
					this.cadastros = result.Documents
          this.searchTerm
          this.cleanFiltered()
				}
			},
			error: (error) => {
				this.notificationService.showMessage(error.status, error.statusText, 'read', this.dynamicUrl)
			},
		})
	}

	readDataRegisterCredPrev(search: any, destino: string) {
		let matricula = search.Identity
		let tipo = search.Type
		this.cadastroService.getDadosCadastraisMT(matricula, tipo).subscribe({
			next: (result) => {
				var response = result.status >= 400 ? this.notificationService.showMessage(result.status, result.error.mensagem, 'read', this.dynamicUrl) : result
				this.response = { erroTecnico: false, destiny: destino, content: response }
				this.messageInfoModal.status = 0
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	readDataRegisterCMFlex(search: any, destino: string) {
		let matricula = search.Identity
		let tipo = search.Type
		this.cadastroService.getDadosCadastraisCM(matricula, tipo).subscribe({
			next: (result) => {
				var response = result.status >= 400 ? this.notificationService.showMessage(result.status, result.error, 'read', this.dynamicUrl) : result
				this.response = { erroTecnico: false, destiny: destino, content: response }
				this.messageInfoModal.status = 0
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	createDataRegisterCredPrev(cadastro: Cadastro) {
		let _cadastro = JSON.parse(JSON.stringify(cadastro))
		this.cadastroService.postDadosCadastraisMT(_cadastro).subscribe({
			next: (result) => {
				this.messageInfoReprocess.status = 2
				this.registerLogs(_cadastro, result)
				this.reprocessResponse = result.status <= 400 ? result.error : result
			},
			error: (error) => {
				this.notificationService.showMessage(error.status, error || error.message, 'create', this.dynamicUrl)
			},
		})
	}

	createDataRegisterCMFlex(cadastro: Cadastro) {
		let _cadastro = JSON.parse(JSON.stringify(cadastro))
		this.cadastroService.postDadosCadastraisCM(_cadastro).subscribe({
			next: (result) => {
				this.messageInfoReprocess.status = 2
				this.registerLogs(_cadastro, result)
				this.reprocessResponse = result.status <= 400 ? result.error : result
			},
			error: (error) => {
				this.notificationService.showMessage(error.status, error || error.message, 'create', this.dynamicUrl)
			},
		})
	}

	registerLogs(cadastro: any, response: any) {
		const newDate = new Date()
		const data = this.dataFormatterService.formatDateToCustomISOString(newDate)
		const _response = response.status == 200 ? response : response.error
		const _integrato = response.status == 200 ? true : false
		const _statusText = response.error == 400 ? response.statusText.replace(' ', '') : response.statusText.replace(' ', '').replace(' ', '')
		const atividade = {
			identity: this.cadastroRegistroAtividade.identity,
			request: JSON.stringify(cadastro),
			search: this.cadastroRegistroAtividade.search,
			response: JSON.stringify(_response),
			integrato: _integrato,
			statusCode: `${_statusText}`,
			destiny: this.cadastroRegistroAtividade.destiny,
			date: data,
			system: 'cadastro',
			type: 'hermes-front',
		}
		this.registroCosmosService.createRegisterCosmos(cosmos_table, 'registros-hermes',atividade).subscribe({
			next: (result) => {
				console.log(result.status, 'Registro Cadastrado com Sucesso!')
				window.location.reload()
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	reprocess(content: any, destino: string, error: boolean) {
		this.messageInfoReprocess.status = 1
		if (destino == 'CredPrev') {
			this.createDataRegisterCredPrev(content)
		} else {
			this.createDataRegisterCMFlex(content)
		}
		this.onDismiss(4000)
	}

	detailModal(cadastro: Cadastro, form: TemplateRef<any>) {
		this.detail.request = cadastro.request == undefined || cadastro.request == "" ? null : JSON.parse(cadastro.request)
		this.detail.response = cadastro?.response == undefined || cadastro?.response == "" ? null : JSON.parse(cadastro?.response)
		this.detail.search = cadastro?.search == undefined || cadastro?.search == "" ? null : JSON.parse(cadastro?.search)
		this.openModal(form, (this.size = 'xl'))
	}


	reprecessModal(cadastro: Cadastro, form: TemplateRef<any>) {
		this.cadastroRegistroAtividade = cadastro
		this.detail.search = JSON.parse(cadastro!.search)

		this.messageInfoModal = { status: 1, message: 'Aguarde enquanto as informações são atualizadas!' }
		this.messageInfoReprocess.status = 0
		this.openModal(form, (this.size = 'xl'))
		if (cadastro.destiny == 'CredPrev') {
			this.reprocessResponse = null
			this.readDataRegisterCredPrev(this.detail.search, cadastro.destiny)
		} else if (cadastro.destiny == 'CMFlex') {
			this.reprocessResponse = null
			this.readDataRegisterCMFlex(this.detail.search, cadastro.destiny)
		}
	}

	openModal(form: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.open(form, { size, scrollable: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	onDismiss(timeout: number) {
		setTimeout(() => {
			this.modalRef.dismiss()
		}, timeout)
		this.cleanFiltered()
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}

	onChangeIntegrato(form: any) {
		this.selectedIntegrato = form
		this.integrato = form
	}

	requiredVerification() {
		if (this.fromDate == null || this.toDate == null) {
			this.requiredMessage = 'Campo obrigatório!'
		} else {
			this.readDataRegister()
		}
	}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date
		} else {
			this.toDate = null
			this.fromDate = date
		}
	}

	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate)
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date)
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input)
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue
	}
}
