<div class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-2 px-2 d-flex justify-content-between align-items-center">
		<div class="input-group search w-25 mb-3" style="min-width: 16rem">
			<input class="form-control" type="text" placeholder="Pesquisar na página" [(ngModel)]="searchTermValue" name="searchTerm" style="height: 2.65rem" />
		</div>
		<form class="row row-cols-sm-auto d-flex justify-content-center align-items-center mb-3">
			<div class="col-12 px-0" style="min-width: 16rem">
				<div class="form-group">
					<input
						type="text"
						placeholder="YYYY-MM-DD"
						class="form-control"
						bsDatepicker
						(bsValueChange)="onDateSelection($event)"
						[bsConfig]="{ adaptivePosition: true, dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' }"
						style="height: 2.65rem"
            #dateInput
					/>
				</div>
			</div>
			<div class="col-12 px-0" style="min-width: 16rem">
				<div class="form-group">
					<select
						class="form-control form-select border-opacity-50 p-2"
						[(ngModel)]="selectedStatus"
						name="selectedStatus"
						(ngModelChange)="onChangeStatus($event)"
					>
						<option selected disabled>Status</option>
						<option value="ativo">Ativo</option>
						<option value="inativo">Inativo</option>
					</select>
				</div>
			</div>

			<div class="col-12 d-flex align-items-center">
				<button type="button" class="btn btn-log-customer btn-customer-info" (click)="readQueryCosmos()">
					<em i-bs name="search"></em>
				</button>
				<button type="button" class="btn btn-log-customer btn-customer-danger" (click)="cleanEndRefresh(dateInput)">
					<em i-bs name="x" width="23" height="23"></em>
				</button>
			</div>
		</form>
	</div>
	<div class="card-body m-0 p-0">
		<div class="table-responsive">
			<div class="d-flex justify-content-center align-items-center py-5" *ngIf="notification.status == 1">
				<strong>{{ notification.message }}</strong>
			</div>
			<div class="d-flex justify-content-center align-items-center py-5" *ngIf="notification.status == 2">
				<div class="spinner-border text-primary" role="status"></div>
			</div>
			<table class="table table-sm table-hover align-middle" *ngIf="records && notification.status == 3">
				<caption style="display: none">
					Tabela mostra informações de query.
				</caption>
				<thead class="table-light">
					<tr>
						<th scope="col" class="align-middle">Título</th>
						<th scope="col" class="align-middle">Data Ativação</th>
						<th scope="col" class="align-middle">Data Inativação</th>
						<th scope="col" class="text-center align-middle">Status</th>
						<th scope="col" class="text-center align-middle">Detalhes</th>
						<th scope="col" class="text-center align-middle">Desativar</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let record of pagefiltered">
						<td class="align-middle">{{ record.title }}</td>
						<td class="align-middle">{{ record.dataInicio }}</td>
						<td class="align-middle">{{ record.dataFim }}</td>
						<td class="text-center align-middle">{{ record.status }}</td>
						<td class="text-center align-middle" style="width: 8rem">
							<button type="button" class="btn btn-customer-info" [disabled]="record.status == 'inativo'" (click)="detailModal(record, modalDetail)">
								<em i-bs name="justify" width="20" height="20"></em>
							</button>
						</td>
						<td class="text-center align-middle" style="width: 8rem">
							<button type="button" class="btn btn-customer-danger" [disabled]="record.status == 'inativo'" (click)="changeActive(record)">
								<em i-bs name="pencil-fill" width="20" height="20"></em>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<ngb-pagination
			class="pagination-customer"
			[collectionSize]="collectionSize"
			[(page)]="page"
			[pageSize]="pageSize"
			(pageChange)="paginateRecords()"
		></ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="paginateRecords()">
			<option [ngValue]="20">20 items por página</option>
			<option [ngValue]="30">30 items por página</option>
			<option [ngValue]="40">40 items por página</option>
		</select>
	</div>
</div>

<ng-template #modalDetail let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="section">
			<div>
				<strong for="service-id">Title: </strong>
				<p class="value" id="service-id">{{ detailAlert.title }}</p>
			</div>
			<hr />
			<div>
				<strong for="service-id">Id Query: </strong>
				<p class="value" id="service-id">{{ detailAlert.idQuery }}</p>
			</div>
			<hr />
			<div>
				<strong for="service-id">Data Início: </strong>
				<p class="value" id="service-id">{{ detailAlert.dataInicio }}</p>
			</div>
			<hr />
			<div>
				<strong for="service-id">Query: </strong>
				<p class="value" id="service-id">{{ detailAlert.query }}</p>
			</div>
			<hr />
			<div>
				<strong for="service-id">Periodo: </strong>
				<p class="value" id="service-id">{{ detailAlert.period }}</p>
			</div>
			<hr />
			<div>
				<strong for="service-id">Porcentagem: </strong>
				<p class="value" id="service-id">{{ detailAlert.percentage }}</p>
			</div>
			<hr />
			<div>
				<strong for="service-id">Porcentagem de Erros: </strong>
				<p class="value" id="service-id">{{ detailAlert.percentageError }}</p>
			</div>
			<hr />
			<div>
				<strong for="service-id">Quantidade de Chamadas: </strong>
				<p class="value" id="service-id">{{ detailAlert.callCount }}</p>
			</div>
			<hr />
			<div>
				<strong for="service-id">Quantidade de Erros: </strong>
				<p class="value" id="service-id">{{ detailAlert.errorCount }}</p>
			</div>
		</div>
	</div>
</ng-template>
