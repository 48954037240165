import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { ModalDismissReasons, NgbDatepickerKeyboardService, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { FileSignIn } from '../../../../models/file-signIn.model'

import { DataLakeService } from '../data-lake.service'
import { DatePickerService } from '../../../../shared/date-picker.service'
import { NotificationService } from './../../../../shared/notification.service'
import { environment } from '../../../../../environments/environment'
import { DateFormatOffsetService } from '../../../../shared/date-format-offset.service'

const DATALAKE_CONTAINER_SIGNIN = environment.DATALAKE_CONTAINER_SIGNIN

@Component({
	selector: 'app-signin-data-lake',
	templateUrl: './signin-data-lake.component.html',
	styleUrls: ['./signin-data-lake.component.scss'],
	providers: [{ provide: NgbDatepickerKeyboardService, useClass: DatePickerService }],
})
export class SigninDataLakeComponent implements OnInit {
	title = 'SignIn'
	dynamicUrl = 'monitoring/insights/data/signin'
	filesData: FileSignIn[] = []
	filesDataEmail: FileSignIn[] = []

	selectedFileDetails!: FileSignIn

	notification = { status: 0, message: '' }

	// Search
	searchDate: any | null = null // Campo de busca por data
	searchTerm: string = '' // Campo de busca por termo
	filteredFilesSignIn: FileSignIn[] = [] // Dados filtrados para exibição
	searchFilter = new FormControl('')
	requiredMessage: string = ''

	// Modal
	modalReference!: NgbModalRef
	modalCloseResult: string = ''

	// Paginação
	page = 1
	pageSize = 500
	collectionSize: number = 0
	paginatedFileSignIn: FileSignIn[] = []

	continuationToken: string | null = null

	constructor(
		private dataLakeService: DataLakeService,
		private modalService: NgbModal,
		private pipe: DatePipe,
		private cdr: ChangeDetectorRef,
		private notificationService: NotificationService,
		private dateFormatOffsetService: DateFormatOffsetService
	) {}

	ngOnInit() {
		this.loadData()
	}

	padZero(number: number): string {
		return number <= 9 ? '0' + number : number.toString()
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.searchTerm = value
		this.filteredFilesSignIn = this.searchTerm ? this.filterFilesSignIn(this.searchTerm) : this.filesData
		this.collectionSize = this.filteredFilesSignIn.length
		this.paginateFileSignIn()
	}

	filterFilesSignIn(term: string): FileSignIn[] {
		const lowerCaseTerm = term.toLowerCase()
		return this.filesData.filter(
			(files) =>
				files.userPrincipalName.toLowerCase().includes(lowerCaseTerm) ||
				// @ts-ignore: Object is possibly 'null'.
				this.pipe.transform(files.createdDateTime, 'dd-MM-yyyy').includes(term)
		)
	}

	paginateFileSignIn() {
		this.paginatedFileSignIn = this.filteredFilesSignIn
			.map((arquivo, i) => ({ index: i + 1, ...arquivo }))
			.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)

		this.paginatedFileSignIn = [...this.paginatedFileSignIn]

		this.cdr.detectChanges()
	}

	loadData() {
		this.collectionSize = 0
		this.notification = { status: 2, message: '' }
		const currentDate = new Date()

		const previousDate = new Date(currentDate)
		previousDate.setDate(currentDate.getDate() - 1)

		const dayString = this.padZero(previousDate.getDate())
		const monthString = this.padZero(previousDate.getMonth() + 1)
		const yearString = previousDate.getFullYear().toString()

		console.log(this.searchDate, 'data')

		const tokenToUse = this.continuationToken ?? undefined

		const dateToUse = this.searchDate
			? [this.searchDate.year.toString(), this.padZero(this.searchDate.month), this.padZero(this.searchDate.day)]
			: [yearString, monthString, dayString]

		console.log('Chamando o serviço para obter dados:', dateToUse)

		this.dataLakeService.getData(DATALAKE_CONTAINER_SIGNIN, dateToUse, tokenToUse, 5000).subscribe({
			next: (response) => {
				if (response.status >= 400) {
					this.notificationService.showMessage(response.status, response.statusText, 'read', this.dynamicUrl)
					this.notification = { status: 4, message: 'Erro ao carregar pastas.' }
				}

				this.processFileDetails(response.folders)
				this.notification = { status: 3, message: '' }
			},
			error: (error) => {
				console.error('Error loading folders:', error)
				this.notificationService.showMessage(error.status, error.message, 'read', this.dynamicUrl)
				this.notification = { status: 4, message: 'Erro ao carregar pastas.' }
			},
		})
	}

	processFileDetails(data: any): void {
		const detailedFiles: FileSignIn[] = []

		Object.keys(data).forEach((key) => {
			const jsonArray = JSON.parse(data[key])
			jsonArray.forEach((item: FileSignIn) => {
				if (item) detailedFiles.push(item)
			})
		})

		this.filesData = detailedFiles.map((file: any) => ({
			...file,
			createdDateTime: this.dateFormatOffsetService.formatDateWithOffset(file.createdDateTime, +3),
		}))
		this.filteredFilesSignIn = this.filesData
		this.collectionSize = this.filesData.length

		this.paginateFileSignIn()
	}

	clearFilters() {
		this.searchTerm = ''
		this.searchDate = null
		this.collectionSize = 0
		this.loadData()
		this.cdr.detectChanges()
	}

	openDetailModal(file: FileSignIn, template: TemplateRef<any>) {
		this.selectedFileDetails = file
		this.openModal(template, 'xl')
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalReference = this.modalService.open(template, { fullscreen: true, scrollable: true })

		this.modalReference.result.then(
			(result) => {
				this.modalCloseResult = `Closed with: ${result}`
			},
			(reason) => {
				this.modalCloseResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	autoDismissModal(timeout: number) {
		setTimeout(() => {
			this.modalReference.dismiss()
			this.ngOnInit()
		}, timeout)
	}

	private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'Pressed ESC to exit'
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'Clicked outside to close'
			default:
				return `Dismissed: ${reason}`
		}
	}
}
