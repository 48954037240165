import { Component, OnInit, TemplateRef } from '@angular/core'
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { RegistroCosmosService } from './../../../../../shared/registro-cosmos.service'
import { NotificationService } from '../../../../../shared/notification.service'
import { InsightService } from './../../insight.service'
import { environment } from '../../../../../../environments/environment'
import { AlertsQuery, TestAlertsQuery } from '../../../../../models/alerts.model'
import { FormControl } from '@angular/forms'

const cosmos_table = environment.COSMOS_TABLE

interface OperationObject {
	api: string
	operation_Name: string
	formatted_Name?: string // Propriedade opcional
	operation: string
	maxTimestamp: Date
}

@Component({
	selector: 'app-query-alert',
	templateUrl: './query-alert.component.html',
	styleUrl: './query-alert.component.scss',
})
export class QueryAlertComponent implements OnInit {
	dynamicUrl = '/monitoring/insights/alerts/querys'

	private queryConfigOperationNames: { dateRangeQuery?: string; defaultQuery: string; title: string; detailsRoute?: string }
	private queryAlerts: { dateRangeQuery?: string; defaultQuery: string; title: string; detailsRoute?: string }
	queryResult: AlertsQuery[] = []
	detailTest: TestAlertsQuery[] = []
	deleteQueryId: string
	selectedOperationName: string = 'Operações'
	selectedOperationDetail = { api: '', operation: '' }
	operationsFormated: any
	operationsResponse: any
	callAndErrorResponse: any

	typeRegisterCosmos: string = 'alerts-query'
	percentageValue: any
	periodValue: string

	notification = { status: 0, message: '' }

	//Modal
	modalRef: NgbModalRef
	closeResult: string = ''

	//Search
	selectedStatus: string = 'Status'
	selectedDate: Date | null = null

	searchTerm: string = ''
	filteredFiles: Array<AlertsQuery> = []
	searchFilter = new FormControl('')

	// paginação
	page = 1
	pageSize = 20
	collectionSize: number
	pagefiltered: AlertsQuery[] = []

	constructor(
		private insightService: InsightService,
		private modalService: NgbModal,
		private registroCosmosService: RegistroCosmosService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.queryConfigOperationNames = this.insightService.getQueryConfig('operationNames')
		this.queryAlerts = this.insightService.getQueryConfig('alerts')
		this.readQueryCosmos()
		if (this.queryConfigOperationNames) {
			this.readOperationNames()
		}
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.searchTerm = value
		this.filteredFiles = this.searchTerm ? this.filterFile(this.searchTerm) : this.queryResult
		this.collectionSize = this.filteredFiles.length
		this.paginateQuery()
	}

	filterFile(term: string): Array<AlertsQuery> {
		console.log(term, 'term')
		const lowerCaseTerm = term.toLowerCase()

		return this.queryResult.filter((file) => {
			return file.title.toLowerCase().includes(lowerCaseTerm)
		})
	}

	paginateQuery() {
		this.pagefiltered = this.filteredFiles
			.map((record, i) => ({ index: i + 1, ...record }))
			.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	onChangeOperationName(value: any) {
		const details = value.split(',')

		this.selectedOperationName = value
		this.selectedOperationDetail = { api: details[0], operation: details[1] }
	}

	readOperationNames() {
		let query = {}

		query = this.queryConfigOperationNames.defaultQuery
		this.insightService.executeQuery({ query: query }).subscribe({
			next: (result) => {
				this.operationsResponse = this.insightService.mapInsightsDataToObjects(result.tables)
				this.operationsFormated = this.updateOperationNames(this.operationsResponse)
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	readQueryCosmos() {
		this.notification.status = 2
		const query = {
			query: `SELECT * FROM c WHERE c.system = '${this.typeRegisterCosmos}'`,
		}
		this.registroCosmosService.readRegisterCosmosSDK(cosmos_table, 'registros-hermes', query).subscribe({
			next: (result) => {
				if (result.status >= 400) {
					this.notificationService.showMessage(result.status, result.error, 'read', this.dynamicUrl)
				} else {
					if (result._count == '0') {
						this.notification = { status: 1, message: 'Resultado não encontrado!' }
					} else {
						this.notification.status = 3
						this.queryResult = result.Documents
						this.filteredFiles = this.queryResult
						this.collectionSize = this.queryResult.length
						this.paginateQuery()
					}
				}
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	insertQueryCosmos() {
		const today = new Date()
		const formattedDate = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0')
		console.log(this.selectedOperationDetail)
		let query = {}

		query = this.queryAlerts.defaultQuery
			.replace('api_name', this.selectedOperationDetail.api)
			.replace('operation_name', this.selectedOperationDetail.operation)
			.replace('periodicidade', this.periodValue)

		const registroHermesQuery = {
			type: this.typeRegisterCosmos,
			title: `${this.selectedOperationDetail.api}-${this.selectedOperationDetail.operation}`,
			query: query,
			percentage: this.percentageValue,
			period: this.periodValue,
		}

		this.registroCosmosService.createRegisterCosmos(cosmos_table, 'registros-hermes', registroHermesQuery).subscribe({
			next: (result) => {
				this.notificationService.showMessage(result.status, 'Registro adicionado com sucesso!', 'read', '/monitoring/insights/alerts/querys')
				this.cleanInputForm()
			},
			error: (error) => {
				console.error(error)
			},
		})
		this.readQueryCosmos()
	}

	readCallAndErrorSummaryByOperation() {
		let query = {}

		query = this.queryAlerts.defaultQuery.replace('name', this.selectedOperationName)

		this.insightService.executeQuery({ query: query }).subscribe({
			next: (result) => {
				this.callAndErrorResponse = this.insightService.mapInsightsDataToObjects(result.tables)
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	updateOperationNames(objects: OperationObject[]): OperationObject[] {
		const replacements: { [key: string]: string } = {
			'59e110b1b5857a0f6062658f': 'atendimento',
			'5968c2f5b5857a0e8857fdf5': 'beneficio',
			'59b6e009b5857a12cce81978': 'controleInterno',
			'5a1d694c058ad5247df22b94': 'corporativo',
			'5a1d694c058ad5247df22b94-v2': 'corporativo',
			'59e1a063b5857a16e8cf723f': 'emprestimo',
			'5a7a19b9712cec3e64da28f5': 'INT_Legados',
			'5a1f5f8069c45e24d073fb09': 'seguridade',
			'59b6bdf8b5857a12cce8196e': 'capitalizacao',
			'59233208b0d21217885942dd': 'autoPatrocinio',
		}

		// Mapeia os objetos e atualiza a propriedade formatted_Name
		const updatedObjects = objects
			.map((obj) => {
				const parts = obj.operation_Name.split(' - ')

				if (parts.length > 1) {
					const beforeSemicolon = parts[0].split(';')[0].trim()
					const afterDash = parts[1].trim()

					const updatedBeforeSemicolon = replacements[beforeSemicolon] || beforeSemicolon

					// Cria uma nova propriedade com o nome formatado
					obj.formatted_Name = `${updatedBeforeSemicolon} - ${afterDash}`
					obj.operation = afterDash
					obj.api = updatedBeforeSemicolon
				} else {
					console.warn(`Formato inválido: ${obj.operation_Name}`)
					// A nova propriedade será igual ao valor original em caso de formato inválido
					obj.formatted_Name = obj.operation_Name
				}

				return obj
			})
			.sort((a, b) => (a.formatted_Name || '').localeCompare(b.formatted_Name || '')) // Ordena por formatted_Name

		//Remove objetos com formatted_Name iguais
		const uniqueObjectsMap = new Map<string, any>()

		updatedObjects.forEach((obj) => {
			const existingObj = uniqueObjectsMap.get(obj.formatted_Name || '')

			if (!existingObj || new Date(obj.maxTimestamp) > new Date(existingObj.maxTimestamp)) {
				uniqueObjectsMap.set(obj.formatted_Name || '', obj)
			}
		})

		const uniqueObjects = Array.from(uniqueObjectsMap.values())

		return uniqueObjects
	}

	deleteQueryCosmos() {
		this.registroCosmosService.deleteRegisterCosmos(cosmos_table, 'registros-hermes', this.deleteQueryId).subscribe({
			next: (result) => {
				this.onDismiss(1000)
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	cleanInputForm() {
		this.searchTerm = ''
		this.selectedOperationName = 'Operações'
		this.percentageValue = 'Percentual - Ex: 10'
		this.periodValue = 'Periodicidade - Ex: 5m'
	}

	testQueryInsights(query: any, form: TemplateRef<any>) {
		this.notification = { status: 0, message: '' }
		this.insightService.executeQuery({ query: query }).subscribe({
			next: (result) => {
				this.notification = { status: 5, message: '' }
				this.detailTest = this.insightService.mapInsightsDataToObjects(result.tables)
				if (this.detailTest.length == 0) {
					this.notification = { status: 4, message: 'Não há dados para essa requisição!' }
				}

				this.openModal(form, 'xl', false)
			},
			error: (error) => console.error(error),
		})
	}

	deleteModal(id: any, form: TemplateRef<any>) {
		this.deleteQueryId = id
		this.openModal(form, 'xl', false)
	}

	openModal(form: TemplateRef<any>, size: string, fullscreen: boolean) {
		this.modalRef = this.modalService.open(form, { size: size, fullscreen: fullscreen, scrollable: true, centered: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	onDismiss(timeout: number) {
		setTimeout(() => {
			this.modalRef.dismiss()
		}, timeout)
		this.readQueryCosmos()
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}
}
