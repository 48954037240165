<div class="card stretch-card">
	<div class="card-header card-header-customer text-center">
		<form class="row row-cols-sm-auto d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="dp-hidden position-absolute">
					<div class="input-group">
						<input
							name="datepicker"
							class="form-control"
							ngbDatepicker
							#datepicker="ngbDatepicker"
							[autoClose]="'outside'"
							(dateSelect)="onDateSelection($event)"
							[displayMonths]="3"
							navigation="none"
							[dayTemplate]="t"
							outsideDays="hidden"
							[startDate]="fromDate!"
							tabindex="-1"
							[(ngModel)]="model"
						/>
						<ng-template #t let-date let-focused="focused">
							<span
								class="custom-day"
								[class.focused]="focused"
								[class.range]="isRange(date)"
								[class.faded]="isHovered(date) || isInside(date)"
								(mouseenter)="hoveredDate = date"
								(mouseleave)="hoveredDate = null"
							>
								{{ date.day }}
							</span>
						</ng-template>
					</div>
				</div>
				<div class="col-12">
					<div class="input-group">
						<input
							#dpFromDate
							class="form-control"
							placeholder="yyyy-mm-dd"
							name="dpFromDate"
							[value]="formatter.format(fromDate)"
							(input)="fromDate = validateDateInput(fromDate, dpFromDate.value)"
						/>
						<button class="btn btn-input-customer" id="button-addon2" (click)="datepicker.toggle()" type="button">
							<em i-bs name="calendar-range"></em>
						</button>
					</div>
				</div>
				<div class="custom-alert" role="alert" *ngIf="requiredMessage && fromDate === null" style="margin-top: 5px">
					{{ requiredMessage }}
				</div>
			</div>

			<div class="col-12">
				<div class="input-group">
					<input
						#dpToDate
						class="form-control"
						placeholder="yyyy-mm-dd"
						name="dpToDate"
						[value]="formatter.format(toDate)"
						(input)="toDate = validateDateInput(toDate, dpToDate.value)"
					/>
					<button class="btn btn-input-customer" (click)="datepicker.toggle()" type="button">
						<em i-bs name="calendar-range"></em>
					</button>
				</div>
				<div class="custom-alert" role="alert" *ngIf="requiredMessage && toDate === null" style="margin-top: 5px">
					{{ requiredMessage }}
				</div>
			</div>

			<!-- Search Input -->
			<div class="input-group search w-25">
				<input class="form-control" type="text" placeholder="Pesquisar na página" [(ngModel)]="searchTermValue" name="searchTerm" />
			</div>

			<!-- Action Buttons -->
			<div class="col-12 d-flex align-items-center">
				<button type="button" class="btn btn-log-customer btn-customer-info" (click)="verifyRequiredFields()">
					<em i-bs name="search"></em>
				</button>
				<button type="button" class="btn btn-log-customer btn-customer-danger ml-2" (click)="clearFilters()">
					<em i-bs name="x" width="23" height="23"></em>
				</button>
			</div>
		</form>
	</div>

	<!-- Card Body -->
	<div class="card-body py-4">
		<div class="table-responsive">
			<!-- Info Messages -->
			<div class="d-flex justify-content-center align-items-center py-5" style="height: 65vh;" *ngIf="notification.status == 1">
				<strong>{{ notification.message }}</strong>
			</div>
			<div class="d-flex justify-content-center align-items-center py-5" style="height: 65vh;" *ngIf="notification.status == 2">
				<div class="spinner-border text-primary" role="status"></div>
			</div>

			<!-- Data Table -->
			<table class="table table-sm table-hover align-middle" *ngIf="notification.status == 3 ">
				<caption style="display: none">
					Tabela mostra informações do envio de dados de bancários.
				</caption>
				<thead class="table-light">
					<tr>
						<th scope="col" class="text-center align-middle">Código</th>
						<th scope="col" class="text-center align-middle">Integrado</th>
						<th scope="col" class="text-center align-middle">Origem</th>
						<th scope="col" class="text-center align-middle">Data</th>
						<th scope="col" class="text-center align-middle" style="width: 8rem">Detalhes</th>
						<!-- <th *ngIf="profile_hermes_tecnico" scope="col" class="text-center align-middle" style="width: 8rem">Reprocessar</th>
            <th *ngIf="profile_hermes_tecnico" scope="col" class="text-center align-middle" style="width: 8rem">Tirar Fila</th> -->
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let bank of paginated">
						<td class="text-center align-middle">{{ bank.identify }}</td>
						<td class="text-center align-middle">
							<em [style.color]="bank.integrato ? 'green' : 'red'" i-bs name="circle-fill" width="8" height="8"></em>
						</td>

						<td class="text-center align-middle">{{ bank.origin }}</td>
						<td class="text-center align-middle">{{ bank.date | date : 'dd-MM-YYYY hh:mm:ss' }}</td>
						<td class="text-center align-middle" style="width: 8rem">
							<button type="button" class="btn btn-customer-info" (click)="detailModal(bank, modalDetail)">
								<em i-bs name="justify" width="20" height="20"></em>
							</button>
						</td>
						<!-- <td *ngIf="profile_hermes_tecnico" class="text-center align-middle" style="width: 8rem" >
							<button type="button" class="btn btn-customer-info" (click)="reprecessModal(bank, modalReprecess)">
								<em i-bs name="arrow-repeat" width="20" height="20"></em>
							</button>
						</td> -->
            <!-- <td *ngIf="profile_hermes_tecnico" class="text-center align-middle" style="width: 8rem" (click)="toRemoveQueue(bank)">
							<button type="button" class="btn btn-customer-info" >
								<em i-bs name="arrow-down-circle" width="20" height="20"></em>
							</button>
						</td> -->
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer card-footer-customer py-4 px-2 d-flex justify-content-between align-items-center">
		<ngb-pagination class="pagination-customer" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshPage()">
		</ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshPage()">
			<option [value]="100">100 items por página</option>
			<option [value]="200">200 items por página</option>
			<option [value]="300">300 items por página</option>
		</select>
	</div>
</div>

<ng-template #modalDetail let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="d-flex">
			<div class="card" style="width: 50%">
				<div class="card-header"><strong>Request</strong></div>
				<div class="card-body">
					<div [innerHTML]="detail.request | highlightJson"></div>
				</div>
			</div>
			<div class="card" style="width: 50%">
				<div class="card-header"><strong>Response</strong></div>
				<div class="card-body">
					<div [innerHTML]="detail.response | highlightJson"></div>
				</div>
			</div>
		</div>
	</div>
</ng-template>