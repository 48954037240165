<div class="card" *ngIf="details">
  <div class="card-body">
    <div class="section">
      <div class="property"><strong>Id:</strong><p class="value">{{ details.id }}</p></div>
      <hr />
      <div class="property"><strong>Created Date Time:</strong><p class="value">{{ details.createdDateTime | date: 'dd-MM-yyyy hh:mm:ss' }}</p></div>
      <hr />
      <div class="property"><strong>User Display Name:</strong><p class="value">{{ details.userDisplayName }}</p></div>
      <hr />
      <div class="property"><strong>User Principal Name:</strong><p class="value">{{ details.userPrincipalName }}</p></div>
      <hr />
      <div class="property"><strong>User Id:</strong><p class="value">{{ details.userId }}</p></div>
      <hr />
      <div class="property"><strong>App Id:</strong><p class="value">{{ details.appId }}</p></div>
      <hr />
      <div class="property"><strong>App Display Name:</strong><p class="value">{{ details.appDisplayName }}</p></div>
      <hr />
      <div class="property"><strong>Ip Address:</strong><p class="value">{{ details.ipAddress }}</p></div>
      <hr />
      <div class="property"><strong>Client App Used:</strong><p class="value">{{ details.clientAppUsed }}</p></div>
      <hr />
      <div class="property"><strong>Correlation Id:</strong><p class="value">{{ details.correlationId }}</p></div>
      <hr />
      <div class="property"><strong>Conditional Access Status:</strong><p class="value">{{ details.conditionalAccessStatus }}</p></div>
      <hr />
      <div class="property"><strong>Is Interactive:</strong><p class="value">{{ details.isInteractive }}</p></div>
      <hr />
      <div class="property"><strong>Risk Detail:</strong><p class="value">{{ details.riskDetail }}</p></div>
      <hr />
      <div class="property"><strong>Risk Level Aggregated:</strong><p class="value">{{ details.riskLevelAggregated }}</p></div>
      <hr />
      <div class="property"><strong>Risk State:</strong><p class="value">{{ details.riskState }}</p></div>
      <hr />
      <div class="property"><strong>Resource Display Name:</strong><p class="value">{{ details.resourceDisplayName }}</p></div>
      <hr />
      <div class="property"><strong>Resource Id:</strong><p class="value">{{ details.resourceId }}</p></div>
    </div>
  </div>
</div>
