import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http'
import { catchError, Observable, of, tap } from 'rxjs'

import { environment } from '../../../environments/environment'

const HOST = environment.HOST

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable({
	providedIn: 'root',
})
export class CadastroService {
	constructor(private http: HttpClient) {}

	getDadosCadastraisMT(matricula: string, tipo: string) {
		const _tipo = tipo !== 'P' ? 'P' : ''
		return this.http.get<any>(`${HOST}/corporativo/v2/dadoscadastraismt?matricula=${matricula}&representante=${_tipo}`, httpOptions).pipe(
			tap((_) => console.log(`Response getDadosCadastraisMT`)),
			catchError(this.handleError<any>('getDadosCadastraisMT', null))
		)
	}

	getDadosCadastraisCM(matricula: string, tipo: string) {
		const _tipo = tipo !== 'P' ? 'P' : ''
		return this.http.get<any>(`${HOST}/corporativo/v2/dadoscadastraiscm?matricula=${matricula}&representante=${_tipo}`, httpOptions).pipe(
			tap((_) => console.log(`Response getDadosCadastraisCM`)),
			catchError(this.handleError<any>('getDadosCadastraisCM', null))
		)
	}

	postDadosCadastraisMT(cadastro: any) {
		return this.http.post<any>(`${HOST}/emprestimo/v2/dadoscadastraismt`, cadastro, httpOptions).pipe(
			tap((_) => console.log(`Response postDadosCadastraisMT`)),
			catchError(this.handleError<any>('postDadosCadastraisMT', null))
		)
	}

	postDadosCadastraisCM(cadastro: any) {
		return this.http.post<any>(`${HOST}/financeiro/v1/dadoscadastraiscm`, cadastro, httpOptions).pipe(
			tap((_) => console.log(`Response postDadosCadastraisCM`)),
			catchError(this.handleError<any>('postDadosCadastraisCM', null))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error)
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO: ${error.error}`)
				console.log(`RETORNO MESSAGE: ${error.error.message}`)
			}
			return of(error)
		}
	}
}
