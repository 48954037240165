<div class="card" *ngIf="location">
  <div class="card-header">
    <h4>Location</h4>
  </div>
  <div class="card-body">
    <div class="section">
      <div><strong>City:</strong><p class="value">{{ location.city }}</p></div>
      <hr />
      <div><strong>State:</strong><p class="value">{{ location.state }}</p></div>
      <hr />
      <div><strong>Country Or Region:</strong><p class="value">{{ location.countryOrRegion }}</p></div>
      <hr />
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header">
    <h4>Geo Coordinates</h4>
  </div>
  <div class="card-body">
    <div class="section">
      <div><strong>Latitude:</strong><p class="value">{{ location.geoCoordinates.latitude }}</p></div>
      <hr />
      <div><strong>Longitude:</strong><p class="value">{{ location.geoCoordinates.longitude }}</p></div>
      <hr />
    </div>
  </div>
</div>
