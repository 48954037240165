import { Component } from '@angular/core'



@Component({
	selector: 'app-alerts',
	templateUrl: './alerts.component.html',
	styleUrl: './alerts.component.scss',
})
export class AlertsComponent {
  title = 'ALERTAS'
}