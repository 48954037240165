<div class="card" *ngIf="deviceDetail">
	<div class="card-header">
		<h4>Device Detail</h4>
	</div>
	<div class="card-body">
		<div class="section">
			<div>
				<strong>Device Id:</strong>
				<p class="value">{{ deviceDetail.deviceId }}</p>
			</div>
			<hr />
			<div>
				<strong>Display Name:</strong>
				<p class="value">{{ deviceDetail.displayName }}</p>
			</div>
			<hr />
			<div>
				<strong>Operating System:</strong>
				<p class="value">{{ deviceDetail.operatingSystem }}</p>
			</div>
			<hr />
			<div>
				<strong>Browser:</strong>
				<p class="value">{{ deviceDetail.browser }}</p>
			</div>
			<hr />
			<div>
				<strong>Is Compliant:</strong>
				<p class="value">{{ deviceDetail.isCompliant }}</p>
			</div>
			<hr />
			<div>
				<strong>Is Managed:</strong>
				<p class="value">{{ deviceDetail.isManaged }}</p>
			</div>
			<hr />
			<div>
				<strong>Trust Type:</strong>
				<p class="value">{{ deviceDetail.trustType }}</p>
			</div>
			<hr />
		</div>
	</div>
</div>
