<div>
	<div class="card">
		<div class="card-header">
			<h4>Request Properties</h4>
		</div>
		<div class="card-body">
			<div class="section">
				<div class="property">
					<strong for="event-time">Event time: </strong>
					<p class="value" id="event-time">{{ operationDetails.timestamp | date : 'dd-MM-YYYY hh:mm:ss' }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="name">Name: </strong>
					<p class="value" id="name">{{ operationDetails.name }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="response-code">Response code: </strong>
					<p class="value" id="response-code">{{ operationDetails.resultCode }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="successful-request">Successful request: </strong>
					<p class="value" id="successful-request">{{ operationDetails.success }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="response-time">Response time: </strong>
					<p class="value" id="response-time">{{ operationDetails.duration }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="url">URL: </strong>
					<p class="value" id="url">{{ operationDetails.url }}</p>
				</div>
			</div>
		</div>
	</div>

	<div class="card" *ngIf="customerDimensions != null">
		<div class="card-header">
			<h4>Custom Properties</h4>
		</div>
		<div class="card-body">
			<div class="section">
				<div>
					<strong for="service-id">Service ID: </strong>
					<p class="value" id="service-id">{{ customerDimensions.ServiceID }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="region">Region: </strong>
					<p class="value" id="region">{{ customerDimensions.Region }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="request-id">Request Id: </strong>
					<p class="value" id="request-id">{{ customerDimensions.RequestId }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="service-name">Service Name: </strong>
					<p class="value" id="service-name">{{ customerDimensions.ServiceName }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="cache">Cache: </strong>
					<p class="value" id="cache">{{ customerDimensions.Cache }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="service-type">Service Type: </strong>
					<p class="value" id="service-type">{{ customerDimensions.ServiceType }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="http-method">HTTP Method: </strong>
					<p class="value" id="http-method">{{ customerDimensions.HTTPMethod }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="api-type">API Type: </strong>
					<p class="value" id="api-type">{{ customerDimensions.APIType }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="subscription-name">Subscription Name: </strong>
					<p class="value" id="subscription-name">{{ customerDimensions.SubscriptionName }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="operation-name">Operation Name: </strong>
					<p class="value" id="operation-name">{{ customerDimensions.OperationName }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="api-revision">API Revision: </strong>
					<p class="value" id="api-revision">{{ customerDimensions.APIRevision }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="api-name">API Name: </strong>
					<p class="value" id="api-name">{{ customerDimensions.APIName }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="product-name">Product Name: </strong>
					<p class="value" id="product-name">{{ customerDimensions.ProductName }}</p>
				</div>
			</div>
		</div>
	</div>

	<div class="card" id="requestBody" *ngIf="customerDimensionsRequestBody !== undefined">
		<div class="card-header"><h4>Request-Body</h4></div>
		<div class="card-body" style="max-height: 20rem; overflow: hidden">
			<div class="section d-flex justify-content-between align-items-start">
        <div style="width: 57vw" *ngIf="contentType === 'json'" [innerHTML]="customerDimensionsRequestBody | highlightJson"></div>
        <div style="width: 57vw" *ngIf="contentType === 'xml'" [innerHTML]="customerDimensionsRequestBody | highlightXml"></div>
				<div class="d-flex justify-content-between align-items-start">
					<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Copiar" (click)="copyText(customerDimensionsRequestBody)">
						<em i-bs name="files" width="20" height="20" class="px-1"> </em>
					</button>
					<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Detalhes" (click)="modalContentDetails(customerDimensionsRequestBody, modalDetail)">
						<em i-bs name="three-dots" width="20" height="20" class="px-1"> </em>
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="card" *ngIf="customerDimensionsResponseBoby != undefined">
		<div class="card-header"><h4>Response-Body</h4></div>
		<div class="card-body" style="max-height: 20rem; overflow: hidden">
			<div class="section d-flex justify-content-between align-items-start">
        <div class="innerHTML" style="width: 57vw" *ngIf="contentType === 'json'" [innerHTML]="customerDimensionsResponseBoby | highlightJson"></div>
        <div style="width: 57vw"  *ngIf="contentType === 'xml'" [innerHTML]="customerDimensionsResponseBoby | highlightXml"></div>
				<div class="d-flex justify-content-between align-items-start">
					<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Copiar" (click)="copyText(customerDimensionsResponseBoby)">
						<em i-bs name="files" width="20" height="20" class="px-1"> </em>
					</button>
					<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Detalhes" (click)="modalContentDetails(customerDimensionsResponseBoby, modalDetail)">
						<em i-bs name="three-dots" width="20" height="20" class="px-1"> </em>
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="card" *ngIf="customMeasurements != null">
		<div class="card-header"><h4>Custom Measurements</h4></div>
		<div class="card-body">
			<div class="section">
				<div class="property">
					<strong for="response-size">Response Size: </strong>
					<p class="value" id="response-size">{{ customMeasurements.ResponseSize }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="request-size">Request Size: </strong>
					<p class="value" id="request-size">{{ customMeasurements.RequestSize }}</p>
				</div>
				<hr />
				<div class="property">
					<strong for="client-time">Client Time (in ms): </strong>
					<p class="value" id="client-time">{{ customMeasurements.ClientTimeInMs }}</p>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #modalDetail let-modal>
	<div class="modal-header">
		<h2 class="modal-title text-primary"><strong style="font-size: 1.5rem">Detalhes</strong></h2>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <div *ngIf="contentType === 'xml'" [innerHTML]="modalDetails | highlightXml"></div>
    <div *ngIf="contentType === 'json'" [innerHTML]="modalDetails | highlightJson"></div>
	</div>
</ng-template>