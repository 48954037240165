import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  isCollapsed = false
	date = new Date()
	year = this.date.getFullYear()

  toggleCollapsed(): void {
		this.isCollapsed = !this.isCollapsed
	}
}
