import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class DataFormatterService {
	formatDateToCustomISOString(date: Date): string {
		const year = date.getUTCFullYear()
		const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
		const day = date.getUTCDate().toString().padStart(2, '0')
		const hours = date.getUTCHours().toString().padStart(2, '0')
		const minutes = date.getUTCMinutes().toString().padStart(2, '0')
		const seconds = date.getUTCSeconds().toString().padStart(2, '0')
		const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0').padEnd(7, '0')

		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`
	}
}
