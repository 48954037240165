import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-data-lake-status-card',
	templateUrl: './data-lake-status-card.component.html',
	styleUrl: './data-lake-status-card.component.scss',
})
export class DataLakeStatusCardComponent {
	@Input() status!: {
		errorCode: number
		failureReason: string
		additionalDetails: string
	}
}
