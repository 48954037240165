<div class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-2 px-2 d-flex justify-content-between align-items-center">
    <div class="input-group search w-25 mb-3" style="min-width: 16rem">
			<input class="form-control" type="text" placeholder="Pesquisar na página" [(ngModel)]="searchTermValue" name="searchTerm" style="height: 2.65rem" />
		</div>
		<form class="row row-cols-sm-auto d-flex justify-content-center align-items-center mb-3">
      <div class="col-12 px=0" style="width: 16rem">
				<div class="form-group">
					<select
						[ngModel]="selectedOperationName"
						class="form-control form-select border-opacity-50 p-2"
						(ngModelChange)="onChangeOperationName($event)"
						name="selectedOperationName"
						required
					>
						<option [selected]="true" disabled>Operações</option>
						<option *ngFor="let operation of operationsFormated" [value]="[operation.api, operation.operation]">{{ operation.formatted_Name }}</option>
					</select>

				</div>
			</div>

			<div class="col-12 px=0" style="width: 16rem">
				<div class="form-group">
					<input  class="form-control" type="text" placeholder="Percentual - Ex: 10" [(ngModel)]="percentageValue" name="percentageValue" style="height: 2.65rem;"/>
				</div>
			</div>

      <div class="col-12 px=0" style="width: 16rem">
				<div class="form-group">
					<input  class="form-control" type="text" placeholder="Periodicidade - Ex: 5m" [(ngModel)]="periodValue" name="periodValue" style="height: 2.65rem;"/>
				</div>
			</div>

			<div class="col-12 d-flex align-items-center">
				<button type="button" class="btn btn-log-customer btn-customer-info" (click)="insertQueryCosmos()">
					<em i-bs name="plus" width="23" height="23"></em>
				</button>
				<button type="button" class="btn btn-log-customer btn-customer-danger" (click)="cleanInputForm()">
					<em i-bs name="x" width="23" height="23"></em>
				</button>
			</div>
		</form>
	</div>
	<div class="card-body m-0 p-0">
		<div class="table-responsive">
			<div class="d-flex justify-content-center align-items-center py-5" *ngIf="notification.status == 1">
				<strong>{{ notification.message }}</strong>
			</div>
			<div class="d-flex justify-content-center align-items-center py-5" *ngIf="notification.status == 2">
				<div class="spinner-border text-primary" role="status"></div>
			</div>
			<table class="table table-sm table-hover align-middle" *ngIf="queryResult && notification.status == 3 || notification.status == 4">
        <caption style="display: none;">Tabela mostra informações de query.</caption>
				<thead class="table-light">
					<tr>
            <th scope="col" class="align-middle">Título</th>
						<th scope="col" class="align-middle">Query</th>
						<th scope="col" class="align-middle">Porcentagem</th>
						<th scope="col" class="text-center align-middle">Período</th>
            <th scope="col" class="text-center align-middle">Teste Query</th>
            <th scope="col" class="text-center align-middle">Excluir</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let query of pagefiltered">
            <td class="align-middle">{{ query.title }}</td>
						<td class="align-middle">{{ query.query }}</td>
						<td class="align-middle">{{ query.percentage }}</td>
						<td class="text-center align-middle">{{ query.period }}</td>
            <td class="text-center align-middle" style="width: 8rem">
							<button type="button" class="btn btn-customer-info" (click)="testQueryInsights(query.query, modalTestQuery)">
								<em i-bs name="check-square" width="20" height="20"></em>
							</button>
						</td>
            <td class="text-center align-middle" style="width: 8rem">
							<button type="button" class="btn btn-customer-danger" (click)="deleteModal(query.id, modalDelete)">
								<em i-bs name="trash-fill" width="20" height="20"></em>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<ng-template #modalTestQuery let-modal>
  <div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
  <div class="modal-body">
    <div class="d-flex justify-content-center align-items-center py-5" *ngIf="notification.status == 4 && this.detailTest.length == 0">
      <strong>{{ notification.message }}</strong>
    </div>
    <div class="d-flex justify-content-center align-items-center py-5" *ngIf="notification.status == 5 && this.detailTest.length == 0">
      <div class="spinner-border text-primary" role="status"></div>
    </div>
    <table class="table table-sm table-hover align-middle" *ngIf="this.detailTest.length != 0">
      <thead class="table-light">
        <tr>
          <th scope="col" class="align-middle">Operação</th>
          <th scope="col" class="align-middle">Chamadas</th>
          <th scope="col" class="text-center align-middle">Error</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of this.detailTest">
          <td class="align-middle">{{ result.operation_Name }}</td>
          <td class="align-middle">{{ result.call_Count }}</td>
          <td class="text-center align-middle">{{ result.error_Count }}</td>
        </tr>
    </table>
  </div>
</ng-template>

<ng-template #modalDelete let-modal>
  <div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Apagar Query</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<fieldset>
			<legend>Deseja realmente excluir essa query ?</legend>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn btn-modal-customer-info m-1" (click)="deleteQueryCosmos()">
      <em i-bs name="check" width="20" height="20"></em>
    </button>
		<button type="button" class="btn btn-modal-customer-danger m-1" (click)="modal.dismiss('Cross click')">
      <em i-bs name="x" width="20" height="20"></em>
    </button>
	</div>
</ng-template>


