import { LogLevel } from '@azure/msal-browser'

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

export const environment = {
  production: true,
  descricaoAmbiente: '',

  COSMOS_TABLE: 'prd-shared',
  HOST: 'https://azbrsp1apip001.azure-api.net',
  PATH_PTA: 'pta/v1',
  PATH_BLOB: 'blob/v1',
  PATH_EMAIL: 'email/v1',
  PATH_COSMOS: 'docs-bd/v1/bds',
  PATH_COSMOS_SDK: 'docs-bd/v1/consultas/bds',
  PATH_APIM: 'apimanagement/v1/hermes',

  HOST_INSIGHTS: 'https://api.applicationinsights.io/v1/apps',
  KEY_INSIGHTS: 'b2c59c76-2d89-49ac-975c-7185442bc1ed', // id encontrado no aplication insigths em API Access
  KEY_INSIGHTS_HERMES: 'dfphipqgkqb3o0h5ldse6z7bhelpcp84jyjis5gw', // api Key crianda em API Access para o hermes

  
  DATALAKE_CONTAINER_SIGNIN: 'signin',
  DATALAKE_CONTAINER_APIM: 'apim-prd',

  HttpHeaders: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'anti-csrf-token': 'csrf_token',
    Pragma: 'no-cache',
    'Ocp-Apim-Subscription-Key': '370990c93cb248c2b2b4a19c31b16d55',
  },

  HttpHeaderInghts: {
    'Content-Type': 'application/json',
    'x-api-key': 'dfphipqgkqb3o0h5ldse6z7bhelpcp84jyjis5gw'
  },

  message: {
    id: 'b1103bc5-e87d-4daf-bc8e-b613d504c1ea',
  },

  perfisUserHermes: {
    hermes_tecnico: 'e3f8729b-5929-4508-a814-1e197b3a0d88',
    hermes_cadastro: 'a95e9bc2-7bac-4737-9a6e-772b61e50c2c',
    hermes_pta: '3dec337f-d176-43bd-86b3-8ece50182034',
    hermes_financeiro: '5a004c5b-7daf-4269-9850-54b163cba19f'
  },
  perfisUserPTA: {
    pta_cadastro: 'd8f6a827-4f09-4ab6-832e-d7e12dea7f6c',
    pta_arrecadacao: '7e29d4dc-449f-4353-9cfc-8d2ea4207055',
    pta_emprestimo: '6fbcae39-5cb2-414a-9138-79dde515b6b5',
    pta_consignataria: 'bf1eb272-f95d-4b04-897a-8e43a808124e',
  }
};

export const msalConfig = {
  auth: {
    clientId: '09a3144e-2965-4899-afa6-a8e3d280bd9f',
    authority: 'https://login.microsoftonline.com/valiaonline.onmicrosoft.com',
    redirectUri: 'https://hermes.valia.com.br/',
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback(LogLevel: any, message: any, containsPii: any) {
        console.log(message)
      },
      logLevel: LogLevel.Verbose,
    },
  },
}
export const protectedResources = {
  endpoint: 'https://graph.microsoft.com/v1.0/me',
  scopes: ['user.read'],
}
