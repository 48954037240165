<div class="card stretch-card">
	<nav class="navbar navbar-expand-lg navbar-light bg-light m-0 p-0">
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/monitoring/data/apim']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">APIM</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/monitoring/data/signin']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">SIGNIN </a>
				</li>
			</ul>
		</div>
	</nav>
	<div class="card-body">
		<router-outlet></router-outlet>
	</div>
</div>
