import { Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'

import { ModalDismissReasons, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker'

import { defineLocale } from 'ngx-bootstrap/chronos'
import { ptBrLocale } from 'ngx-bootstrap/locale'

import { Banking } from '../../../models/banking.model'

import { environment } from '../../../../environments/environment.production'

import { FinanceiroService } from './../financeiro.service'
import { NotificationService } from '../../../shared/notification.service'
import { RegistroCosmosService } from './../../../shared/registro-cosmos.service'

import { DateFormatOffsetService } from '../../../shared/date-format-offset.service'

defineLocale('pt-br', ptBrLocale)
const cosmos_table = environment.COSMOS_TABLE

interface Detail {
  request: object,
  response: {
    Code: string
    Message: string | object
  }
}


@Component({
	selector: 'app-generic-financial',
	templateUrl: './generic-financial.component.html',
	styleUrl: './generic-financial.component.scss',
})
export class GenericFinancialComponent implements OnInit {
	register: Banking
	registers: Banking[] = []

	registersCount: number = 0

	notification = { status: 0, message: '' }
	detail: Detail = { request: {}, response: { Code: '', Message: '' } }

	// Calendar
	selectedDate: Date[] | null = null
	bsConfig: Partial<BsDatepickerConfig>

	// Search
	searchTerm: string = ''
	filtered: Banking[] = []
	searchFilter = new FormControl('')
	requiredMessage: string = ''

	// Pagination
	page = 1
	pageSize = 100
	collectionSize: number = 0
	paginated: Banking[] = []

	// Modal
	size: string = ''
	modalRef!: NgbModalRef
	closeResult: string = ''

	private queryConfig: { dateRangeQuery?: string; title: string; detailsRoute?: string }

	constructor(
		public formatter: NgbDateParserFormatter,
		private calendar: NgbCalendar,
		private router: Router,
		private financeiroService: FinanceiroService,
		private notificationService: NotificationService,
		private registroCosmosService: RegistroCosmosService,
		private modalService: NgbModal,
		private dateFormatOffsetService: DateFormatOffsetService,
		private localeService: BsLocaleService
	) {
		this.localeService.use('pt-br')
		this.bsConfig = {
			rangeInputFormat: 'YYYY-MM-DD',
			dateInputFormat: 'YYYY-MM-DD',
			containerClass: 'theme-dark-blue',
			displayMonths: 2,
			showPreviousMonth: true,
			showTodayButton: true,
			maxDate: new Date(),
		}
	}

	ngOnInit(): void {
		const queryType = this.router.url.includes('retorno') ? 'retorno' : 'remessa'
		this.queryConfig = this.financeiroService.getQueryConfig(queryType)
		this.loadBankingData()
	}

	adicionaZero(numero: number) {
		if (numero <= 9) return '0' + numero
		else return numero
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.searchTerm = value
		this.filtered = this.searchTerm ? this.filter(this.searchTerm) : this.registers
		this.collectionSize = this.filtered.length
		this.refreshPage()
	}

	filter(term: string): Banking[] {
		const lowerCaseTerm = term.toLowerCase()
		return this.registers.filter((bank) => {
			return bank.identify.toLowerCase().includes(lowerCaseTerm) || bank.origin.toLowerCase().includes(lowerCaseTerm)
		})
	}

	refreshPage(): void {
		this.paginated = this.filtered
			.map((bank, i) => ({ index: i + 1, ...bank }))
			.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	onDateSelection(event: any) {
		this.selectedDate = event
	}

	loadBankingData(): void {
		const hasDate = this.selectedDate !== null

		const date = new Date()
		const formattedDate = this.formatDate(date)

		let formattedFromDate = ''
		let formattedToDate = ''

		if (hasDate) {
			const dateStart = new Date(this.selectedDate![0])
			const dateEnd = new Date(this.selectedDate![1])

			formattedFromDate = `'${dateStart.getFullYear()}-${this.adicionaZero(dateStart.getMonth() + 1)}-${this.adicionaZero(dateStart.getDate())}T00:00:00.000Z'`
			formattedToDate = `'${dateEnd.getFullYear()}-${this.adicionaZero(dateEnd.getMonth() + 1)}-${this.adicionaZero(dateEnd.getDate())}T23:59:59.000Z'`
		} else {
			formattedFromDate = `'${formattedDate}T00:00:00.000Z'`
			formattedToDate = `'${formattedDate}T23:59:59.000Z'`
		}

		const query = {
			query: this.queryConfig.dateRangeQuery!.replace('{start_date}', formattedFromDate).replace('{end_date}', formattedToDate),
		}

		this.notification.status = 2
		this.financeiroService.readRegisterCosmosSDK(cosmos_table, 'registros-hermes', query).subscribe({
			next: (result) => {
				if (result.status >= 400) {
					this.notificationService.showMessage(result.status, result.error.mensagem, 'read', this.queryConfig.detailsRoute)
				} else {
					if (result._count === '0') {
						this.notification = { status: 1, message: 'Nenhum dado encontrado para o dia pesquisado!' }
					} else {
						this.notification.status = 3
						this.registers = result.Documents
						this.registers = result.Documents.map((register: any) => ({
							...register,
							date: this.dateFormatOffsetService.formatDateWithOffset(register.date, +3),
							request: JSON.parse(register.request),
							response: JSON.parse(register.response),
						}))

						this.filtered = this.registers
						console.log(this.registers, 'retorno')
						this.collectionSize = this.registers.length
						this.refreshPage()
						this.registersCount = result._count
					}
				}
			},
			error: (error) => {
				this.notificationService.showMessage(error.status, error, 'read', this.queryConfig.detailsRoute)
				console.error(error)
			},
		})
	}

	private formatDate(date: Date | { year: number; month: number; day: number }, isStart: boolean = true): string {
		if (date instanceof Date) {
			const day = this.adicionaZero(date.getDate())
			const month = this.adicionaZero(date.getMonth() + 1)
			const year = date.getFullYear()
			return `${year}-${month}-${day}`
		} else {
			const day = this.adicionaZero(date.day)
			const month = this.adicionaZero(date.month)
			const year = date.year
			return `${year}-${month}-${day}T${isStart ? '00:00:0000000Z' : '23:59:5900000Z'}`
		}
	}

	// createReturnFile(content: Banking) {
	// 	this.financeiroService.postArquivosRetorno(content).subscribe({
	// 		next: (result) => {
	// 			console.log(result, 'postRetornoBancario')
	// 			if (result.status === 200) {
	// 				if (content.origin === 'Accesstage') {
	// 					this.readUpdateStatus(content.identify)
	// 				}
	// 			}
	// 		},
	// 		error: (error) => {
	// 			console.error(error, 'postRetornoBancario')
	// 		},
	// 	})
	// }

	// readUpdateStatus(id: string) {
	// 	const body = { ConfirmacaoRetiradaRequest: { trackingID: `${id}`, nmeArquivo: '' } }
	// 	this.financeiroService.postAtualizaStatusArquivo(body).subscribe({
	// 		next: (result) => {
	// 			console.log(result, 'atualiza status')
	// 		},
	// 		error: (error) => {
	// 			console.error(error, 'atualiza status')
	// 		},
	// 	})
	// }

	// toRemoveQueue(content: Banking) {
	//   this.readUpdateStatus(content.id)

	// }

	// reprecessModal(content: Banking, form: TemplateRef<any>) {
	// 	this.openModal(form, (this.size = 'xl'))
	// 	this.createReturnFile(content)
	// }

  detailModal(register: Banking, form: TemplateRef<any>) {
    console.log(register, 'registro');

    // Função auxiliar para verificar e retornar JSON parseado, ou manter como string
    function safeParseMessage(message: string): string | object {
      try {
        const parsed = JSON.parse(message);
        if (typeof parsed === 'object' && parsed !== null) {
          return parsed; // Se o parse der certo e for um objeto JSON válido
        }
      } catch (e) {
        // O JSON.parse falhou, retorna a string original
        return message;
      }
      return message;
    }

    if (register.origin == "accesstage") {
      const response = register?.response;
      this.detail.request = register.request;

      if (response !== null) {
        this.detail.response = {
          Code: response.Code,
          Message: safeParseMessage(response.Message), // Utiliza a função para verificar e parsear
        };
      }
    } else {
      const response = register?.response;
      console.log(typeof response.Message, 'type');
      this.detail.request = register.request;
      this.detail.response = register.integrato == true
        ? register?.response
        : {
            Code: response.Code,
            Message: safeParseMessage(response.Message), // Mesma função para verificar e parsear
          };
    }
    this.openModal(form, (this.size = 'xl'))
  }



	// detailModal(register: Banking, form: TemplateRef<any>) {
	// 	console.log(register, 'registro')
	// 	if (register.origin == "accesstage") {

	// 		const response = register?.response
  //     this.detail.request = register.request

	// 		if (response !== null) {
	// 			this.detail.response = {
	// 				Code: response.Code,
	// 				Message: JSON.parse(response.Message),
	// 			}
	// 		}
	// 	} else {
  //     const response = register?.response
  //     console.log(typeof response.Message, 'tipe')
  //     this.detail.request = register.request
  //     this.detail.response = register.integrato == true ? register?.response : {
  //       Code: response.Code,
  //       Message: JSON.parse(response.Message),
  //     }
	// 	}
  //   this.openModal(form, (this.size = 'xl'))

	// }

	// async copyRequestFile(fileRequest: any) {
	//   try {
	// 		const decodedText = atob(fileRequest.byteArquivo)
	// 		await navigator.clipboard.writeText(decodedText)
	//     this.notificationService.showMessage(200, 'Arquivo copiado para a area de Transferência', 'info')
	// 	} catch (error) {
	// 		console.error('Failed to copy:', error)
	// 	}
	// }

	downloadRequestFile(fileRequest: any) {
		try {
			const fileName = fileRequest.nomeArquivo
			const decodedText = atob(fileRequest.byteArquivo)
			const fileType = 'text/plain'

			const blob = new Blob([decodedText], { type: fileType })
			const url = URL.createObjectURL(blob)

			const a = document.createElement('a')
			a.href = url
			a.download = fileName
			a.click()
			URL.revokeObjectURL(url)
		} catch (error) {
			console.error('Failed to copy:', error)
		}
	}

	validateDateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input)
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue
	}

	verifyRequiredFields(): void {
		if (!this.selectedDate) {
			this.requiredMessage = 'Campo obrigatório!'
		} else {
			this.loadBankingData()
		}
	}

	clearFilters(input: HTMLInputElement): void {
		if (this.selectedDate !== null) {
			input.value = ''
			this.searchTermValue = ''
			this.onDateSelection(null)
			this.loadBankingData()
		} else {
			input.value = ''
			this.searchTermValue = ''
			this.requiredMessage = ''
			this.notification = { status: 0, message: '' }
		}
	}

	openModal(form: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.open(form, { size, scrollable: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	onDismiss(timeout: number) {
		setTimeout(() => {
			this.modalRef.dismiss()
		}, timeout)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}
}
