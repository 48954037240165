<div id="sidebar" class="d-flex flex-column flex-shrink-0 bg-light">
	<a href="/" class="d-block p-3 align-middle link-dark text-decoration-none" title="Icon-only" ngbTooltip="Valia" placement="right">
		<img src="{{ imgPath }}" alt="Valia" width="40" aria-label="Valia" />
	</a>
	<ul class="nav nav-pills nav-flush flex-column mb-auto text-center">
		<li class="nav-item">
			<a
				[routerLink]="['/home']"
				class="nav-link align-middle py-3 border-bottom"
				[routerLinkActive]="['active']"
				[routerLinkActiveOptions]="{ exact: true }"
				aria-current="page"
				ngbTooltip="Home"
				placement="right"
			>
				<img src="{{ home }}" alt="Home" width="24" height="24" aria-label="Home" />
			</a>
		</li>
		<li *ngIf="profile_hermes_tecnico">
			<a
				[routerLink]="['/monitoring/metrics']"
				class="nav-link align-middle py-3 border-bottom"
				[routerLinkActive]="['active']"
				[routerLinkActiveOptions]="{ exact: true }"
				ngbTooltip="Monitoramento"
				placement="right"
			>
				<img src="{{ monitoring }}" alt="Monitoring" width="24" height="24" aria-label="Monitoring" />
			</a>
		</li>
		<li *ngIf="profile_hermes_tecnico || profile_hermes_cadastro">
			<a
				[routerLink]="['/cadastro']"
				class="nav-link align-middle py-3 border-bottom"
				[routerLinkActive]="['active']"
				[routerLinkActiveOptions]="{ exact: true }"
				ngbTooltip="Cadastro"
				placement="right"
			>
				<img src="{{ people }}" alt="Cadastro" width="24" height="24" aria-label="Cadastro" />
			</a>
		</li>
		<li *ngIf="profile_hermes_tecnico || profile_hermes_pta">
			<a
				[routerLink]="['/pta']"
				class="nav-link align-middle py-3 border-bottom"
				[routerLinkActive]="['active']"
				[routerLinkActiveOptions]="{ exact: true }"
				ngbTooltip="PTA"
				placement="right"
			>
				<img src="{{ pta }}" alt="PTA" width="24" height="24" aria-label="PTA" />
			</a>
		</li>
		<li *ngIf="profile_hermes_tecnico || profile_hermes_financeiro">
			<a
				[routerLink]="['/financeiro/bancario/retorno']"
				class="nav-link align-middle py-3 border-bottom"
				[routerLinkActive]="['active']"
				[routerLinkActiveOptions]="{ exact: true }"
				ngbTooltip="Financeiro"
				placement="right"
			>
				<img src="{{ money }}" alt="Financeiro" width="24" height="24" aria-label="Financeiro" />
			</a>
		</li>
	</ul>
	<div class="row d-flex align-items-center justify-content-center py-3 m-0 link-dark" style="background-color: #004e98">
		<div class="col">
			<div ngbDropdown class="d-inline-block">
				<button type="button" id="dropdownBasic" ngbDropdownToggle style="color: #fff; border: none; background-color: transparent">
					<strong [ngbTooltip]="user" placement="right" container="body">{{ nomeReduzido }}</strong>
				</button>
				<div ngbDropdownMenu aria-labelledby="dropdownBasic">
					<strong ngbDropdownItem id="user" class="text-primary">{{ nome }}</strong>
					<button ngbDropdownItem class="d-flex justify-content-center align-items-center" (click)="onLogout()">
						Logout <em i-bs name="arrow-bar-right" width="20" height="20" class="px-1"></em>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #user
	>Olá, <strong>{{ nome }}</strong
	>!</ng-template
>
