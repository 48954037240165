<div class="card m-3 stretch-card">
	<div class="card-body p-5 my-5">
    <div class="card-header card-header-customer py-3" style="text-align: center">
      <h1 class="text-primary">{{ title }}</h1>
    </div>
		<div class="row" *ngIf="mensagem">
			<div class="col-sm-12 text-center p-5">
				<!--<p [innerHTML]="textoMensagem" class="text-secondary" style="font-weight: 500"></p>-->
        <h4>{{ mensagem }}</h4>
        <img src="{{ valia }}" alt="Home" aria-label="Home" style="padding-top: 3rem;"/>
			</div>
		</div>
	</div>
</div>

