import { Injectable } from '@angular/core'

import { HttpClient, HttpEventType } from '@angular/common/http'
import { catchError, Observable, of, tap, timeout } from 'rxjs'
import { environment } from '../../environments/environment'

const HOST = environment.HOST
const PATH_COSMOS = environment.PATH_COSMOS
const PATH_COSMOS_SDK = environment.PATH_COSMOS_SDK
const APIM = environment.PATH_APIM

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable({
	providedIn: 'root',
})
export class RegistroCosmosService {
	constructor(private http: HttpClient) {}

	readRegisterCosmos(bd: string, colecao: string, query: object): Observable<any> {
		return this.http.post<any>(`${HOST}/${PATH_COSMOS}/${bd}/colecoes/${colecao}`, JSON.stringify(query), httpOptions).pipe(
			timeout(300000),
			tap((_) => console.log(`Response readRegisterCosmos`)),
			catchError(this.handleError<any>('readRegisterCosmos', HttpEventType.Response))
		)
	}

	readRegisterCosmosSDK(bd: string, colecao: string, query: object): Observable<any> {
		return this.http.post<any>(`${HOST}/${PATH_COSMOS_SDK}/${bd}/colecoes/${colecao}`, JSON.stringify(query), httpOptions).pipe(
			timeout(300000),
			tap((_) => console.log(`Response readRegisterCosmos`)),
			catchError(this.handleError<any>('readRegisterCosmos', HttpEventType.Response))
		)
	}
  // preciso ainda terminar essa url
  updateRegisterCosmos(bd: string, colecao: string, id: string, body: object): Observable<any> {
		return this.http.put<any>(`${HOST}/${APIM}/bds/${bd}/colecoes/${colecao}/docs/${id}`, body, httpOptions ).pipe(
			timeout(300000),
			tap((_) => console.log(`Response readRegisterCosmos`)),
			catchError(this.handleError<any>('readRegisterCosmos', HttpEventType.Response))
		)
	}

	createRegisterCosmos(bd: string, colecao: string, atividade: any) {
		return this.http.post<any>(`${HOST}/${APIM}/bds/${bd}/colecoes/${colecao}/docs/particao`, atividade, httpOptions).pipe(
			tap((_) => console.log(`Response createRegisterCosmos`)),
			catchError(this.handleError<any>('createRegisterCosmos', null))
		)
	}

	deleteRegisterCosmos(bd: string, colecao: string, id: string) {
		return this.http.delete<any>(`${HOST}/${APIM}/bds/${bd}/colecoes/${colecao}/docs/${id}`, httpOptions).pipe(
			tap((_) => console.log(`Response deleteRegisterCosmos`)),
			catchError(this.handleError<any>('deleteRegisterCosmos', null))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error)
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO: ${error.error}`)
				console.log(`RETORNO MESSAGE: ${error.error.message}`)
			}
			return of(error)
		}
	}
}
