<div class="card stretch-card">
	<div class="card-header card-header-customer text-center">
		<form class="row row-cols-sm-auto d-flex justify-content-center align-items-center">
      <!-- Calendar Input -->
			<div class="col-12">
				<div class="form-group" style="width: 30rem">
					<input
						type="text"
						name="bsValue"
						class="form-control"
						bsDaterangepicker
						placeholder="YYYY-MM-DD HH:mm:ss  - YYYY-MM-DD HH:mm:ss"
						[bsConfig]="bsConfig"
						(bsValueChange)="onDateSelection($event)"
            #daterangepickerInput
					/>
				</div>
				<div class="custom-alert" role="alert" *ngIf="requiredMessage && toDate === null" style="margin-top: 5px">
					{{ requiredMessage }}
				</div>
			</div>

			<!-- Search Input -->
			<div class="input-group search w-25">
				<input class="form-control" type="text" placeholder="Pesquisar na página" [(ngModel)]="searchTermValue" name="searchTerm" />
			</div>

			<!-- Action Buttons -->
			<div class="col-12 d-flex align-items-center">
				<button type="button" class="btn btn-log-customer btn-customer-info" (click)="verifyRequiredFields(daterangepickerInput)">
					<em i-bs name="search"></em>
				</button>
				<button type="button" class="btn btn-log-customer btn-customer-danger ml-2" (click)="clearFilters(daterangepickerInput)">
					<em i-bs name="x" width="23" height="23"></em>
				</button>
			</div>
		</form>
	</div>

	<!-- Card Body -->
	<div class="card-body py-4">
		<div class="table-responsive">
			<!-- Info Messages -->
			<div class="d-flex justify-content-center align-items-center py-5" style="height: 65vh" *ngIf="notification.status == 1">
				<strong>{{ notification.message }}</strong>
			</div>
			<div class="d-flex justify-content-center align-items-center py-5" style="height: 65vh" *ngIf="notification.status == 2">
				<div class="spinner-border text-primary" role="status"></div>
			</div>

			<!-- Data Table -->
			<table class="table table-sm table-hover align-middle" *ngIf="insightsCount > 0 && notification.status != 2">
				<caption style="display: none">
					Tabela mostra informações referente ao App Insights Peformance.
				</caption>
				<thead class="table-light">
					<tr>
						<th scope="col" class="align-middle">Data</th>
						<th scope="col" class="align-middle">Operação</th>
						<th *ngIf="queryType === 'failures'" scope="col" class="text-center align-middle">Total</th>
						<th scope="col" class="text-center align-middle">Count</th>
						<th scope="col" class="text-center align-middle" style="width: 8rem">Detalhes</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let data of paginatedInsights">
						<td class="align-middle">{{ data.timestamp }}</td>
						<td class="align-middle">{{ data.operation_Name }}</td>
						<td *ngIf="queryType === 'failures'" class="text-center align-middle">{{ data.total_Calls }}</td>
						<td class="text-center align-middle">{{ data.call_Count }}</td>
						<td class="text-center align-middle" style="width: 8rem">
							<button type="button" class="btn btn-customer-info" (click)="viewDetails(data.call_IDs)">
								<em i-bs name="justify" width="20" height="20"></em>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer card-footer-customer py-4 px-2 d-flex justify-content-between align-items-center">
		<ngb-pagination class="pagination-customer" [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="paginateInsights()">
		</ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="paginateInsights()">
			<option [value]="100">100 items por página</option>
			<option [value]="200">200 items por página</option>
			<option [value]="300">300 items por página</option>
		</select>
	</div>
</div>
