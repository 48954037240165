import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-lake',
  templateUrl: './data-lake.component.html',
  styleUrl: './data-lake.component.scss'
})
export class DataLakeComponent implements OnInit {
  constructor(private router: Router){}

  ngOnInit(): void {

  }

  routerApim() {
    this.router.navigateByUrl('/insight/dados/apim')
  }

  routerSignIn() {
    this.router.navigateByUrl('/insight/dados/signin')
  }
}
