<div class="card stretch-card my-3">
	<div class="card-header card-header-customer py-4 px-2 d-flex justify-content-center align-items-center">
		<!-- <form class="row row-cols-sm-auto d-flex justify-content-center align-items-center">
			 <div class="row">
        <div class="col-xs-12 col-12 col-md-4 col-lg-4 form-group">
          <select
            class="custom-select form-control"
            [(ngModel)]="colorTheme" (ngModelChange)="applyTheme(dp);">
            <option value="theme-default">default</option>
            <option value="theme-green">green</option>
            <option value="theme-blue">blue</option>
            <option value="theme-dark-blue">dark-blue</option>
            <option value="theme-red">red</option>
            <option value="theme-orange">orange</option>
          </select>
        </div>

        <div class="col-xs-12 col-12 col-md-5 col-lg-5 form-group">
          <div class="form-group">
            <div class="input-group">
              <input type="text" class="form-control" bsDatepicker #dp="bsDatepicker" [bsConfig]="bsConfig">
              <div class="input-group-btn">
                <button class="btn btn-success"
                        (click)="dp.show()"
                        [attr.aria-expanded]="dp.isOpen"
                        type="button">
                  Open
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
		</form> -->
	</div>
	<div class="card">
		<div class="card-header-custumer">
			<h5>Sucesso</h5>
		</div>
		<div echarts [options]="chartOptionSuccess" class="chart" (chartClick)="onChartClick($event, 'Success', modalDetail)"></div>
	</div>
	<div class="card">
		<div class="card-header-custumer">
			<h5>Falhas</h5>
		</div>
		<div echarts [options]="chartOptionFailures" class="chart" (chartClick)="onChartClick($event, 'Failures', modalDetail)"></div>
	</div>
</div>

<ng-template #modalDetail let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="onDismiss()"></button>
	</div>
	<div class="modal-body">
		<div class="table-responsive">
			<table class="table table-sm table-hover align-middle" *ngIf="resultMetrics">
				<caption style="display: none">
					Tabela mostra informações do envio de arquivo PTA.
				</caption>
				<thead class="table-light">
					<tr>
						<th scope="col" class="align-middle">Operações</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let metrics of resultMetrics.operations">
						<td class="align-middle">{{ metrics }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</ng-template>
