import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Pipe({
	name: 'highlightXml',
})
export class HighlightXmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: string): SafeHtml {
		if (!value) {
			return value
		}

		const formattedXml = this.formatXml(value)

		let highlightedXml = formattedXml.replace(/</g, '&lt;').replace(/>/g, '&gt;')

		// Highlighting XML tags (with namespaces if present)
		highlightedXml = highlightedXml.replace(/(&lt;\/?[\w:.-]+(?:\s+[\w:.-]+(?:="[^"]*")?)?\/?&gt;)/g, '<span style="color: #054995; font-weight: 600;">$1</span>')

		// Highlighting text values between tags
		highlightedXml = highlightedXml.replace(/&gt;\s*([^<]+?)\s*&lt;/g, '&gt;<span style="color: #2a9d8f;">$1</span>&lt;')

		// Detect and skip highlighting inside CDATA sections
		highlightedXml = highlightedXml.replace(/(&lt;!\[CDATA\[)([\s\S]*?)(\]\]&gt;)/g, (match, start, cdataContent, end) => {
			// Leave CDATA content as is, without applying any custom styles
			return `<span style="color: #e76f51;">${start}${cdataContent}${end}</span>`
		})

		return this.sanitizer.bypassSecurityTrustHtml('<pre>' + highlightedXml + '</pre>')
	}

	private formatXml(xml: string): string {
		const reg = /(>)(<)(\/*)/g
		let formatted = xml.replace(reg, '$1\r\n$2$3')
		let pad = 0
		const lines = formatted.split('\r\n')
		let indent = 0
		formatted = lines
			.map((line) => {
				if (line.match(/<\w[^>]*\/>$/)) {
					return line + '\r\n'
				} else if (line.match(/.+<\/\w[^>]*>$/)) {
					indent = 0
				} else if (line.match(/^<\/\w/) && pad > 0) {
					pad -= 1
				} else if (line.match(/^<\w[^>]*[^\/]>.*$/)) {
					indent = 1
				} else {
					indent = 0
				}

				const padding = new Array(pad * 4 + 1).join(' ') // Aumentando o padding para 4 espaços por nível
				pad += indent

				return padding + line
			})
			.join('\r\n')
		return formatted
	}
}

