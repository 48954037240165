<div class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-3" style="text-align: center">
		<h2 class="text-primary">{{ title }}</h2>
	</div>

	<nav class="navbar navbar-expand-sm navbar-light bg-light m-0 p-0">
		<div class="collapse navbar-collapse" style="display: inline-block;">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/monitoring/metrics']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">METRICS</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/monitoring/insights/performance']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">INSIGHT</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/monitoring/data/apim']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">DATA</a>
				</li>
			</ul>
		</div>
	</nav>

	<div class="card-body" style="padding: 0 0 0 0 !important; margin: 0 !important; overflow: hidden">
		<router-outlet></router-outlet>
	</div>
</div>
