import { Injectable } from '@angular/core'
import { DatePipe } from '@angular/common'

@Injectable({
	providedIn: 'root',
})
export class DateFormatOffsetService {
	constructor(private datePipe: DatePipe) {}

	formatDateWithOffset(dateString: string, offsetHours: number): string {
		if (!dateString) {
			console.warn('Data vazia fornecida.')
			return ''
		}

		const date = new Date(dateString)

		if (isNaN(date.getTime())) {
			console.error(`Data inválida: ${dateString}`)
			return 'Data inválida'
		}

		const localTime = new Date(date.getTime() + offsetHours * 60 * 60 * 1000)

		return this.datePipe.transform(localTime, 'dd-MM-yyyy HH:mm:ss') || ''
	}
}
