import { Component, Input } from '@angular/core'
import { FileSignIn } from '../../../models/file-signIn.model'

@Component({
	selector: 'app-data-lake-detail-card',
	templateUrl: './data-lake-detail-card.component.html',
	styleUrl: './data-lake-detail-card.component.scss',
})
export class DataLakeDetailCardComponent {
	@Input() details!: FileSignIn
}
