<div class="card stretch-card">
	<nav class="navbar navbar-expand-sm navbar-light bg-light m-0 p-0">
		<div class="collapse navbar-collapse" style="display: inline-block;">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/monitoring/insights/performance']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
						>PERFORMANCE</a
					>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/monitoring/insights/failures']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
						>FAILURES
					</a>
				</li>
			</ul>
		</div>
	</nav>
	<div class="card-body" style="padding: 0 0 0 0 !important; margin: 0 !important; overflow: hidden">
		<router-outlet></router-outlet>
	</div>
</div>
