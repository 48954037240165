import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-data-lake-device-card',
	templateUrl: './data-lake-device-card.component.html',
	styleUrl: './data-lake-device-card.component.scss',
})
export class DataLakeDeviceCardComponent {
	@Input() deviceDetail!: {
		deviceId: string
		displayName: string
		operatingSystem: string
		browser: string
		isCompliant: boolean
		isManaged: boolean
		trustType: string
	}
}
