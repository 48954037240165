import { Component } from '@angular/core'

import { LoginService } from '../../../shared/login.service'

@Component({
	selector: 'app-insight',
	templateUrl: './insight.component.html',
	styleUrl: './insight.component.scss',
})
export class InsightComponent {
  title = 'INSIGHT'

  loginDisplay = false
  constructor(public loginService: LoginService,) {
    this.loginService.ngOnInit()
    this.loginDisplay = this.loginService.authenticated
  }
}
