import { Component, OnInit } from '@angular/core'
import { LoginService } from '../../shared/login.service'

@Component({
	selector: 'app-monitoring',
	templateUrl: './monitoring.component.html',
	styleUrl: './monitoring.component.scss',
})
export class MonitoringComponent implements OnInit {
  title = 'MONITORAMENTO'

  loginDisplay = false
  constructor(public loginService: LoginService,) {
    this.loginService.ngOnInit()
    this.loginDisplay = this.loginService.authenticated
  }
	ngOnInit(): void {}
}
