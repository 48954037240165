<div class="card" *ngIf="status">
  <div class="card-header">
    <h4>Status</h4>
  </div>
  <div class="card-body">
    <div class="section">
      <div><strong>Error Code:</strong><p class="value">{{ status.errorCode }}</p></div>
      <hr />
      <div><strong>Failure Reason:</strong><p class="value">{{ status.failureReason }}</p></div>
      <hr />
      <div><strong>Additional Details:</strong><p class="value">{{ status.additionalDetails }}</p></div>
      <hr />
    </div>
  </div>
</div>
