import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-data-lake-location-card',
	templateUrl: './data-lake-location-card.component.html',
	styleUrl: './data-lake-location-card.component.scss',
})
export class DataLakeLocationCardComponent {
	@Input() location!: {
		city: string
		state: string
		countryOrRegion: string
		geoCoordinates: {
			latitude: number
			longitude: number
		}
	}
}
