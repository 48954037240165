import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http'
import { catchError, Observable, of, tap } from 'rxjs'

import { environment } from '../../../environments/environment'

const HOST = environment.HOST
const PATH_PTA = environment.PATH_PTA

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable({
	providedIn: 'root',
})
export class PtaService {
	constructor(private http: HttpClient) {}

	getProcessos(): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/processos`, httpOptions).pipe(
			tap((_) => console.log(`Response getProcessos`)),
			catchError(this.handleError<any>('getProcessos', null))
		)
	}

	getEmpresas(): Observable<any> {
		return this.http.get<any>(`${HOST}/${PATH_PTA}/empresas`, httpOptions).pipe(
			tap((_) => console.log(`Response getEmpresas`)),
			catchError(this.handleError<any>('getEmpresas', null))
		)
	}

	getUsuarioPorEmail(email: string): Observable<any> {
		if (!email.trim()) {
			return of(`${email} não localizado`)
		}
		return this.http.get<any>(`${HOST}/${PATH_PTA}/usuario?email=${email}`, httpOptions).pipe(
			tap((_) => console.log(`Response getUsuario`)),
			catchError(this.handleError<any>('getUsuario', null))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
