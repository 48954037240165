import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { MsalGuard } from '@azure/msal-angular'
import { AuthGuardService } from './guard/auth.guard.service'

import { HomeComponent } from './pages/home/home.component'
import { FinanceiroComponent } from './pages/financeiro/financeiro.component'

import { CadastroComponent } from './pages/cadastro/cadastro.component'
import { PtaComponent } from './pages/pta/pta.component'
import { MetricComponent } from './pages/monitoring/insight/metric/metric.component'
import { MonitoringComponent } from './pages/monitoring/monitoring.component'
import { DataLakeComponent } from './pages/monitoring/dataLake/data-lake.component'

import { SigninDataLakeComponent } from './pages/monitoring/dataLake/signin-data-lake/signin-data-lake.component'
import { ApimDataLakeComponent } from './pages/monitoring/dataLake/apim-data-lake/apim-data-lake.component'

import { InsightComponent } from './pages/monitoring/insight/insight.component'
import { GenericInsightComponent } from './pages/monitoring/insight/generic-insight/generic-insight.component'
import { DetailInsightComponent } from './pages/monitoring/insight/detail-insight/detail-insight.component'
import { OperationDetailComponent } from './pages/monitoring/insight/detail-insight/operation-detail/operation-detail.component'

import { AlertInsightComponent } from './pages/monitoring/insight/alert-insight/alert-insight.component'
import { GenericFinancialComponent } from './pages/financeiro/generic-financial/generic-financial.component'
import { QueryAlertComponent } from './pages/monitoring/insight/alert-insight/query-alert/query-alert.component'
import { RecordAlertComponent } from './pages/monitoring/insight/alert-insight/record-alert/record-alert.component'


const routes: Routes = [
	{ path: '', component: HomeComponent, canActivate: [MsalGuard, AuthGuardService] },
	{ path: 'home', component: HomeComponent, canActivate: [MsalGuard, AuthGuardService] },
	{ path: 'pta', component: PtaComponent, canActivate: [MsalGuard, AuthGuardService] },
	{ path: 'cadastro', component: CadastroComponent, canActivate: [MsalGuard, AuthGuardService] },
	{
		path: 'financeiro',
		component: FinanceiroComponent,
		canActivate: [MsalGuard, AuthGuardService],
		children: [
			{ path: 'bancario/retorno', component: GenericFinancialComponent },
			{ path: 'bancario/remessa', component: GenericFinancialComponent },
		],
	},
	{
		path: 'monitoring',
		component: MonitoringComponent,
		canActivate: [MsalGuard, AuthGuardService],
		children: [
      { path: 'metrics', component: MetricComponent, canActivate: [MsalGuard, AuthGuardService] },
			{
				path: 'insights',
        component: InsightComponent,
        canActivate: [MsalGuard, AuthGuardService],
				children: [
					{
						path: 'performance',
            component: GenericInsightComponent, canActivate: [MsalGuard, AuthGuardService]
					},
					{
						path: 'performance/details',
						component: DetailInsightComponent,
            children: [{ path: ':id', component: OperationDetailComponent, canActivate: [MsalGuard, AuthGuardService] } ],
					},
					{
						path: 'failures',
            component: GenericInsightComponent, canActivate: [MsalGuard, AuthGuardService]
					},
					{
						path: 'failures/details',
						component: DetailInsightComponent,
            children: [{ path: ':id', component: OperationDetailComponent, canActivate: [MsalGuard, AuthGuardService] }],
					},
					{
						path: 'alerts',
            component: AlertInsightComponent, canActivate: [MsalGuard, AuthGuardService],
						children: [
              { path: 'querys', component: QueryAlertComponent, canActivate: [MsalGuard, AuthGuardService] },
              { path: 'records', component: RecordAlertComponent, canActivate: [MsalGuard, AuthGuardService] },
						],
					},
				],
			},
			{
				path: 'data',
        component: DataLakeComponent, canActivate: [MsalGuard, AuthGuardService],
				children: [
          { path: 'signin', component: SigninDataLakeComponent, canActivate: [MsalGuard, AuthGuardService] },
          { path: 'apim', component: ApimDataLakeComponent, canActivate: [MsalGuard, AuthGuardService] },
				],
			},
		],
	},
]

const isIframe = window !== window.parent && !window.opener

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
