import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker'

import { FileApim } from '../../../../models/file-apim.model'

import { DataLakeService } from '../data-lake.service'
import { NotificationService } from './../../../../shared/notification.service'
import { environment } from '../../../../../environments/environment'
import { DateFormatOffsetService } from '../../../../shared/date-format-offset.service'

const DATALAKE_CONTAINER_APIM = environment.DATALAKE_CONTAINER_APIM

@Component({
	selector: 'app-apim-data-lake',
	templateUrl: './apim-data-lake.component.html',
	styleUrl: './apim-data-lake.component.scss',
})
export class ApimDataLakeComponent implements OnInit {
	dynamicUrl = 'monitoring/insights/data/apim'
	filesData: FileApim[] = []
	folderPaths: any[] = []

	selectedFileDetails!: any
	contentType: string
	modalDetails: any

	notification = { status: 0, message: '' }

	// Search
	searchTerm: string = ''
	searchOperationName: string = ''
	filteredFiles: FileApim[] = []
	searchFilter = new FormControl('')
	requiredMessage: string = ''

	// Modal
	modalReference!: NgbModalRef
	modalCloseResult: string = ''
	size: string

	// Paginação
	page = 1
	pageSize = 100
	collectionSize: number = 0
	paginatedFile: FileApim[] = []

	continuationToken: string | null = null

	// Calendar
	selectedDate: Date | null = null
	bsConfig: Partial<BsDatepickerConfig>

	constructor(
		private dataLakeService: DataLakeService,
		private notificationService: NotificationService,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private dateFormatOffsetService: DateFormatOffsetService,
		private localeService: BsLocaleService
	) {
		this.page = 0
		this.localeService.use('pt-br')
		this.bsConfig = {
			dateInputFormat: 'YYYY-MM-DD HH:mm',
			containerClass: 'theme-dark-blue',
			displayMonths: 2,
			showPreviousMonth: true,
			showTodayButton: true,
			maxDate: new Date(),
		}
	}

	ngOnInit(): void {
		this.loadData()
	}

	padZero(number: number): string {
		return number <= 9 ? '0' + number : number.toString()
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	// set searchTermValue(value: string) {
	// 	this.searchTerm = value
	// 	this.page = 1

	// 	this.filteredFiles = this.filterFilesApim(this.searchTerm)
	// 	this.collectionSize = this.filteredFiles.length
	// 	this.paginateFileApim()
	// }

	// filterFilesApim(term: string): Array<FileApim> {
	// 	const lowerCaseTerm = term.toLowerCase()

	// 	return this.filesData.filter((file) => {
	// 		return (
	// 			file.operationName?.toLowerCase().includes(lowerCaseTerm) ||
	// 			file.apiName?.toLowerCase().includes(lowerCaseTerm) ||
	// 			file.productName?.toLowerCase().includes(lowerCaseTerm) ||
	// 			file.method?.toLowerCase().includes(lowerCaseTerm)
	// 		)
	// 	})
	// }

	set searchTermValue(value: string) {
		this.searchTerm = value
		this.filteredFiles = this.searchTerm ? this.filter(this.searchTerm) : this.filesData
		this.collectionSize = this.filteredFiles.length
		this.paginateFileApim()
	}

	filter(term: string): FileApim[] {
		const lowerCaseTerm = term.toLowerCase()
		return this.filesData.filter((file) => {
			return (
				file.operationName?.toLowerCase().includes(lowerCaseTerm) ||
				file.apiName?.toLowerCase().includes(lowerCaseTerm) ||
				file.productName?.toLowerCase().includes(lowerCaseTerm) ||
				file.method?.toLowerCase().includes(lowerCaseTerm)
			)
		})
	}

	paginateFileApim() {
		this.paginatedFile = this.filteredFiles
			.map((arquivo, i) => ({ index: i + 1, ...arquivo }))
			.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)

		this.paginatedFile = [...this.paginatedFile]

		this.cdr.detectChanges()
	}

	onDateSelection(event: any) {
		this.selectedDate = event
	}

	loadData(isLoadMore: boolean = false) {
		const hasDate = this.selectedDate !== null

		let params: string[] = []

		this.notification = { status: 2, message: 'Por favor, aguarde!' }
		const currentDate = new Date()
		currentDate.setHours(currentDate.getHours() - 1)

		let dayString = ''
		let monthString = ''
		let yearString = ''
		let hoursString = ''
		let minutesString = ''

		if (hasDate) {
			const date = new Date(this.selectedDate!)
			console.log(date, 'data Selecionada')

			dayString = this.padZero(date.getDate())
			monthString = this.padZero(date.getMonth() + 1)
			yearString = date.getFullYear().toString()
			hoursString = this.padZero(date.getHours())
			minutesString = this.padZero(date.getMinutes())

			params = [yearString, monthString, dayString, hoursString, minutesString]
		} else {

			dayString = this.padZero(currentDate.getDate())
			monthString = this.padZero(currentDate.getMonth() + 1)
			yearString = currentDate.getFullYear().toString()
			hoursString = this.padZero(currentDate.getHours())
			minutesString = this.padZero(currentDate.getMinutes())

			params = [yearString, monthString, dayString, hoursString, minutesString]
		}

		const tokenToUse = this.continuationToken ?? undefined

		this.dataLakeService.getData(DATALAKE_CONTAINER_APIM, params, tokenToUse,this.pageSize, this.searchOperationName).subscribe({
			next: (response) => {
				if (response.status >= 400) {
					this.notificationService.showMessage(response.status, response.statusText, 'read', this.dynamicUrl)
					this.notification = { status: 4, message: 'Erro ao carregar pastas.' }
				}
				const extractedValues = Object.values(response.folders).map((value: any) => {
					const parsedValue = JSON.parse(value)

					if (parsedValue.dataHora) {
						parsedValue.dataHora = this.dateFormatOffsetService.formatDateWithOffset(parsedValue.dataHora, +3)
					}

					return parsedValue
				})

				if (!isLoadMore) {
					this.filesData = extractedValues
				} else {
					this.filesData = [...this.filesData, ...extractedValues]
				}

				this.filteredFiles = this.filesData
				console.log(this.filteredFiles)
				this.collectionSize = this.filesData.length

				this.continuationToken = response.continuationToken || null

				this.paginateFileApim()
				this.notification = { status: 3, message: '' }
			},
			error: (error) => {
				console.error('Error loading data:', error)
				this.notificationService.showMessage(error.status, error.message, 'read', this.dynamicUrl)
				this.notification = { status: 4, message: 'Erro ao carregar pastas.' }
			},
		})
	}

	searchData() {
		this.loadData(false)
		this.searchOperationName
		console.log(this.searchOperationName, 'operatio')
	}

	// searchData() {
	// 	this.continuationToken = null
	// 	this.loadData(false)
	// 	this.searchOperationName
	// 	console.log(this.searchOperationName, 'operatio')
	// }

	// loadMoreData() {
	// 	if (!this.continuationToken) {
	// 		console.log('Não há mais dados para carregar.')
	// 		return // Se não há token, não faz mais nada
	// 	}
	// 	this.loadData(true)
	// }

	clearFilters(input: HTMLInputElement): void {
		if (this.selectedDate !== null) {
			input.value = ''
			this.searchTermValue = ''
			this.onDateSelection(null)
			this.continuationToken = null
			this.cdr.detectChanges()
			this.ngOnInit()
		} else {
			input.value = ''
			this.searchTermValue = ''
			this.requiredMessage = ''
			this.notification = { status: 0, message: '' }
			this.continuationToken = null
			this.page = 1
		}
	}

	updateTagValueInXml(xmlString: string): string {
		const partialTagName = 'GlobalSerial' // Fragmento do nome da tag
		const newValue = 'PARGLOBAL REMOVIDO - HERMES'

		const parser = new DOMParser()
		const xmlDoc = parser.parseFromString(xmlString, 'application/xml')

		// Obter todas as tags no documento
		const allElements = xmlDoc.getElementsByTagName('*') // Pega todas as tags no XML

		// Filtrar as tags que contêm 'GlobalSerial' no nome da tag
		const matchingElements = Array.from(allElements).filter((element) => element.tagName.includes(partialTagName))

		if (matchingElements.length === 0) {
			console.warn(`Tag contendo "${partialTagName}" não encontrada no XML.`)
			return xmlString
		}

		matchingElements.forEach((element) => {
			while (element.firstChild) {
				element.removeChild(element.firstChild)
			}
			const textNode = xmlDoc.createTextNode(newValue) // Substituir pelo novo valor
			element.appendChild(textNode)
		})

		const serializer = new XMLSerializer()
		const newXmlString = serializer.serializeToString(xmlDoc)
		return newXmlString
	}

	isJsonOrXml(input: string | null): 'json' | 'xml' | 'unknown' {
		if (input === null) {
			this.contentType = 'unknown'
			return 'unknown'
		}

		// Removendo espaços em branco no início e no fim da string
		const trimmedInput = input.trim()
		this.contentType = ''

		// Verificação simples para JSON
		if (trimmedInput.startsWith('{') || trimmedInput.startsWith('[')) {
			try {
				JSON.parse(trimmedInput)
				this.contentType = 'json'
				return 'json'
			} catch (e) {
				this.contentType = 'unknown'
				return 'unknown'
			}
		}

		// Verificação simples para XML
		if (trimmedInput.startsWith('<') && trimmedInput.endsWith('>')) {
			this.contentType = 'xml'
			return 'xml'
		}

		this.contentType = 'unknown'
		return 'unknown'
	}

	async copyText(registro: any) {
		try {
			const text = typeof registro === 'string' ? registro : JSON.stringify(registro)
			await navigator.clipboard.writeText(text)
		} catch (error) {
			console.error('Failed to copy:', error)
		}
	}

	modalContentDetails(content: any, form: TemplateRef<any>): void {
		this.modalDetails = content
		this.openModal(form, 'xl', false)
	}

	openDetailModal(file: any, template: TemplateRef<any>) {
		this.selectedFileDetails = {
			...file,
			requestBody: {
				contentType: `${this.isJsonOrXml(file.requestBody)}`,
				content: this.isJsonOrXml(file.requestBody) === 'json' ? JSON.parse(file.requestBody) : this.updateTagValueInXml(file.requestBody),
			},
			requestBodyEndPoint: {
				contentType: `${this.isJsonOrXml(file.requestBodyEndPoint)}`,
				content:
					this.isJsonOrXml(file.requestBodyEndPoint) === 'json' ? JSON.parse(file.requestBodyEndPoint) : this.updateTagValueInXml(file.requestBodyEndPoint),
			},
			responseBody: {
				contentType: `${this.isJsonOrXml(file.responseBody)}`,
				content: this.isJsonOrXml(file.responseBody) === 'json' ? JSON.parse(file.responseBody) : this.updateTagValueInXml(file.responseBody),
			},
		}
		this.openModal(template, '', true)
	}

	openModal(template: TemplateRef<any>, size: string, fullscreen: boolean) {
		this.modalReference = this.modalService.open(template, { size: size, fullscreen: fullscreen, scrollable: true, centered: true })

		this.modalReference.result.then(
			(result) => {
				this.modalCloseResult = `Closed with: ${result}`
			},
			(reason) => {
				this.modalCloseResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	autoDismissModal(timeout: number) {
		setTimeout(() => {
			this.modalReference.dismiss()
			this.ngOnInit()
		}, timeout)
	}

	private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'Pressed ESC to exit'
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'Clicked outside to close'
			default:
				return `Dismissed: ${reason}`
		}
	}
}
