export class Cadastro {
  id!: string;
  identity!: string;
  integrato!: boolean;
  destiny!: string
  date!: Date;
  request!: string;
  search!: any;
  response!: string;
  statusCode?: string;
  _ts?: number
}

export class Detais {
  request!: object;
  search!: object;
  response!: object;
}
