import { NotificationService } from './../../../../../shared/notification.service'
import { Component, OnInit, TemplateRef } from '@angular/core'
import { AlertsRecords } from '../../../../../models/alerts.model'
import { RegistroCosmosService } from '../../../../../shared/registro-cosmos.service'
import { environment } from '../../../../../../environments/environment'
import { FormControl } from '@angular/forms'
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

const cosmos_table = environment.COSMOS_TABLE

@Component({
	selector: 'app-record-alert',
	templateUrl: './record-alert.component.html',
	styleUrl: './record-alert.component.scss',
})
export class RecordAlertComponent implements OnInit {
	dynamicUrl = '/monitoring/insights/alerts/records'
	notification = { status: 0, message: '' }
	typeRecordsCosmos = 'alerts-records'
	detailAlert: AlertsRecords

	records: AlertsRecords[]

	//Search
	selectedStatus: string = 'Status'
	selectedDate: Date | null = null

	searchTerm: string = ''
	filteredFiles: Array<AlertsRecords> = []
	searchFilter = new FormControl('')

	// paginação
	page = 1
	pageSize = 20
	collectionSize: number
	pagefiltered: AlertsRecords[] = []

	//Modal
	modalRef: NgbModalRef
	closeResult: string = ''

	constructor(private registroCosmosService: RegistroCosmosService, private notificationService: NotificationService, private modalService: NgbModal) {}

	ngOnInit(): void {
		this.readQueryCosmos()
	}

	convertToTimestamp(dateString: string) {
		const date = new Date(dateString)
		return date.getTime() / 1000
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.searchTerm = value
		this.filteredFiles = this.searchTerm ? this.filterFile(this.searchTerm) : this.records
		this.collectionSize = this.filteredFiles.length
		this.paginateRecords()
	}

	filterFile(term: string): Array<AlertsRecords> {
    console.log(term, 'term')
		const lowerCaseTerm = term.toLowerCase()

		return this.records.filter((file) => {
			return file.title.toLowerCase().includes(lowerCaseTerm)
		})
	}

	paginateRecords() {
		this.pagefiltered = this.filteredFiles
			.map((record, i) => ({ index: i + 1, ...record }))
			.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	readQueryCosmos() {
		// Verifica se selectedDate não é nulo
		const hasDate = this.selectedDate !== null

		// Inicializa as variáveis para timestamps
		let formattedFromDate = 0
		let formattedToDate = 0

		// Formata as datas se hasDate for verdadeiro
		if (hasDate) {
			const dayInitial = String(this.selectedDate!.getDate())
			const monthInitial = String(this.selectedDate!.getMonth() + 1)
			const yearInitial = this.selectedDate!.getFullYear()

			formattedFromDate = this.convertToTimestamp(`${yearInitial}/${monthInitial}/${dayInitial} 00:00:00`)
			formattedToDate = this.convertToTimestamp(`${yearInitial}/${monthInitial}/${dayInitial} 23:59:59`)
		}

		const status = this.selectedStatus === 'Status' ? 'ativo' : this.selectedStatus
		this.notification.status = 2

		let queryString = `SELECT * FROM c WHERE c.system = '${this.typeRecordsCosmos}'`

		if (!hasDate) {
			queryString += ` AND c.status='${status}'`
		}


		if (hasDate) {
			if (this.selectedStatus !== 'Status') {
				queryString += ` AND (c._ts >= ${formattedFromDate} AND c._ts <= ${formattedToDate}) AND c.status='${this.selectedStatus}' `
			} else {
				queryString += ` AND (c._ts >= ${formattedFromDate} AND c._ts <= ${formattedToDate})`
			}
		}

		const query = {
			query: queryString,
		}

		this.registroCosmosService.readRegisterCosmosSDK(cosmos_table, 'registros-hermes', query).subscribe({
			next: (result) => {
        if(result.status >= 400) {
          this.notificationService.showMessage(result.status, result.error, 'read', this.dynamicUrl)
        } else {
          if(result._count == "0") {
            this.notification = { status: 1, message: 'Resultado não encontrado!' }
          } else {
            this.notification.status = 3
            this.records = result.Documents
            this.filteredFiles = this.records
            this.collectionSize = this.records.length
            this.paginateRecords()
          }

        }


			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	onChangeStatus(value: any) {
		this.selectedStatus = value
	}

	onDateSelection(event: any) {
		this.selectedDate = event
		console.log(event, 'event')
	}

	cleanEndRefresh(input: HTMLInputElement) {
    input.value = ''
    this.searchTerm = ''
    this.selectedDate = null
		this.selectedStatus = 'Status'
    this.paginateRecords()
		this.readQueryCosmos()
	}

	changeActive(register: AlertsRecords) {
		const currentDate = new Date()
		const formattedDate = currentDate.toISOString()

		const registerUpdate = {
			...register,
			status: 'inativo',
			dataFim: formattedDate,
		}

		this.registroCosmosService.updateRegisterCosmos(cosmos_table, 'registros-hermes', register.id, registerUpdate).subscribe({
			next: (result) => {
				if (result.status >= 400) {
					this.notificationService.showMessage(result.status, result.statusText, 'update', this.dynamicUrl)
					this.notification = { status: 4, message: 'Erro ao carregar pastas.' }
				}

				this.notificationService.showMessage(result.status, 'Dados atualizados com sucesso!', 'update', this.dynamicUrl)
				this.readQueryCosmos()
			},
			error: (error) => console.error(error),
		})
	}

	detailModal(register: AlertsRecords, form: TemplateRef<any>) {
		this.detailAlert = register
		this.openModal(form, 'xl', false)
	}

	openModal(form: TemplateRef<any>, size: string, fullscreen: boolean) {
		this.modalRef = this.modalService.open(form, { size: size, fullscreen: fullscreen, scrollable: true, centered: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	onDismiss(timeout: number) {
		setTimeout(() => {
			this.modalRef.dismiss()
		}, timeout)
		this.readQueryCosmos()
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}
}
